<div class="layout-container" [ngClass]="containerClass">
  <div class="layout-content-wrapper">
    <app-topbar></app-topbar>

    <router-outlet></router-outlet>
    @if (headquarterId_()) {
      <app-profilemenu></app-profilemenu>
    }
    <app-config></app-config>
    <div class="layout-mask"></div>
  </div>
</div>
