import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "check_NaN_number",
})
export class CheckNaNNumberPipe implements PipeTransform {
  transform(value: number): string {
    if (isNaN(value)) return "---";
    return value.toString();
  }
}
