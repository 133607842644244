import { CommonModule } from "@angular/common";
import {
  Component,
  OnDestroy,
  Renderer2,
  ViewChild,
  computed,
  inject,
} from "@angular/core";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { ConfigComponent } from "@layout/components/config/config.component";
import { ProfileSidebarComponent } from "@layout/components/profile-sidebar/profile-sidebar.component";
import { SidebarComponent } from "@layout/components/sidebar/sidebar.component";
import { TopbarComponent } from "@layout/components/topbar/topbar.component";
import { RouteParamService } from "@services/route-param.service";
import { Subscription, filter } from "rxjs";
import { LayoutService } from "../../service/app.layout.service";
import { MenuService } from "../../service/app.menu.service";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrl: "./layout.component.scss",
  standalone: true,
  imports: [
    RouterOutlet,
    ProfileSidebarComponent,
    TopbarComponent,
    CommonModule,
    ConfigComponent,
  ],
})
export class AppLayoutComponent implements OnDestroy {
  overlayMenuOpenSubscription: Subscription;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menuOutsideClickListener: any;

  @ViewChild(SidebarComponent) appSidebar!: SidebarComponent;
  public routeParamService = inject(RouteParamService);
  public headquarterId_ = computed(() =>
    this.routeParamService.headquarterId_()
  );

  constructor(
    private menuService: MenuService,
    public layoutService: LayoutService,
    public renderer: Renderer2,
    public router: Router
  ) {
    this.overlayMenuOpenSubscription =
      this.layoutService.overlayOpen$.subscribe(() => {
        if (!this.menuOutsideClickListener) {
          this.menuOutsideClickListener = this.renderer.listen(
            "document",
            "click",
            (event) => {
              const isOutsideClicked = !(
                this.appSidebar.el.nativeElement.isSameNode(event.target) ||
                this.appSidebar.el.nativeElement.contains(event.target) ||
                event.target.classList.contains("p-trigger") ||
                event.target.parentNode.classList.contains("p-trigger")
              );
              if (isOutsideClicked) {
                this.layoutService.state.overlayMenuActive = false;
                this.layoutService.state.staticMenuMobileActive = false;
                this.layoutService.state.menuHoverActive = false;
                this.menuService.reset();
                this.menuOutsideClickListener();
                this.menuOutsideClickListener = null;
                this.unblockBodyScroll();
              } else {
                if (this.layoutService.state.staticMenuMobileActive) {
                  this.blockBodyScroll();
                }
              }
            }
          );
        }
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.unblockBodyScroll();
      });
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add("blocked-scroll");
    } else {
      document.body.className += " blocked-scroll";
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  }

  get containerClass() {
    return {
      "layout-light": this.layoutService.config.colorScheme === "light",
      "layout-dim": this.layoutService.config.colorScheme === "dim",
      "layout-dark": this.layoutService.config.colorScheme === "dark",
      "layout-colorscheme-menu":
        this.layoutService.config.menuTheme === "colorScheme",
      "layout-primarycolor-menu":
        this.layoutService.config.menuTheme === "primaryColor",
      "layout-transparent-menu":
        this.layoutService.config.menuTheme === "transparent",
      "layout-overlay": this.layoutService.config.menuMode === "overlay",
      "layout-static": this.layoutService.config.menuMode === "static",
      "layout-slim": this.layoutService.config.menuMode === "slim",
      "layout-horizontal": this.layoutService.config.menuMode === "horizontal",
      "layout-static-inactive":
        this.layoutService.state.staticMenuDesktopInactive &&
        this.layoutService.config.menuMode === "static",
      "layout-overlay-active": this.layoutService.state.overlayMenuActive,
      "layout-mobile-active": this.layoutService.state.staticMenuMobileActive,
      "p-input-filled": this.layoutService.config.inputStyle === "filled",
      "p-ripple-disabled": !this.layoutService.config.ripple,
    };
  }

  ngOnDestroy() {
    if (this.overlayMenuOpenSubscription) {
      this.overlayMenuOpenSubscription.unsubscribe();
    }

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
    }
  }
}
