import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "raw_value",
})
export class RawValuePipe implements PipeTransform {
  transform(value: object, ...args: string[]): string {
    if (!value || !args || args?.length <= 1) return "---";

    return `${args
      .map((arg: string) => (value as { [key: string]: string })?.[arg])
      .filter((item) => item)
      .join(" - ")}`;
  }
}
