<div class="layout-topbar">
  <div class="topbar-start">
    @if (this.headquarterId_()) {
      <button
        type="button"
        class="topbar-menubutton p-link p-trigger"
        (click)="onMenuButtonClick()"
      >
        <i class="pi pi-bars"></i>
      </button>
    }

    <!-- <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb> -->
  </div>

  <div class="topbar-end flex">
    <p-dropdown
      [options]="(headquarters$ | async) ?? []"
      optionLabel="name"
      optionValue="id"
      placeholder="Chọn trụ sở"
      [ngModel]="selectedHeadquarterId_()"
      (ngModelChange)="selectedHeadquarterId_.set($event)"
    ></p-dropdown>

    <ul class="topbar-menu">
      <li class="topbar-profile">
        <button
          type="button"
          class="p-link"
          (click)="onProfileButtonClick()"
          style="border-radius: 50%; overflow: hidden"
        >
          <img
            [src]="
              this.meStore.profile_()?.avatar ||
              'assets/layout/images/avatar-default.png'
            "
            alt="Profile"
          />
        </button>
      </li>
    </ul>
  </div>
</div>
