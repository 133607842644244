import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "money",
})
export class MoneyPipe implements PipeTransform {
  transform(value: number): string {
    if (value)
      return value.toLocaleString("it-IT", {
        style: "currency",
        currency: "VND",
      });
    else return "0";
  }
}
