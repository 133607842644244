import { QueryParams } from "@interfaces/query-params.interface";

export function autoFillFieldParams(queryParams: QueryParams | undefined): {
  [key: string]: string | boolean | number | string[];
} {
  return queryParams
    ? {
        q: queryParams.q ?? [],
        sort: queryParams.sort ?? [],
        page: queryParams.page ?? 0,
        size: queryParams.size ?? 20,
        regexOptions: queryParams.regexOptions ?? "i",
      }
    : {
        q: [],
        sort: [],
        page: 0,
        size: 20,
        regexOptions: "i",
      };
}
