export const PRIVILEGE_TYPE = {
  view: {
    code: "view",
    translationCode: "common.type.allow_page_codes_privilege_type.view",
  },
  create: {
    code: "create",
    translationCode: "common.type.allow_page_codes_privilege_type.create",
  },
  update: {
    code: "update",
    translationCode: "common.type.allow_page_codes_privilege_type.update",
  },
  delete: {
    code: "delete",
    translationCode: "common.type.allow_page_codes_privilege_type.delete",
  },
  detail: {
    code: "detail",
    translationCode: "common.type.allow_page_codes_privilege_type.detail",
  },
  linking: {
    code: "linking",
    translationCode: "common.type.allow_page_codes_privilege_type.linking",
  },
  manage: {
    code: "manage",
    translationCode: "common.type.allow_page_codes_privilege_type.manage",
  },
  active: {
    code: "active",
    translationCode: "common.type.allow_page_codes_privilege_type.active",
  },
  pushSMS: {
    code: "pushSMS",
    translationCode: "common.type.allow_page_codes_privilege_type.push_sms",
  },
  pushImmediate: {
    code: "pushImmediate",
    translationCode:
      "common.type.allow_page_codes_privilege_type.push_immediate",
  },
};
