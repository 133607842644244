import { Pipe, PipeTransform } from "@angular/core";
import { FEType } from "@models/front-end";
import { PARKING_CARD_TYPE } from "@constants/type";

@Pipe({
  name: "parking_card_type",
})
export class ParkingCardTypePipe implements PipeTransform {
  transform(value?: number): string {
    if (value != 0 && !value) return "";

    const listParkingCardTypes = Object.values(PARKING_CARD_TYPE);

    return (
      listParkingCardTypes.find((item: FEType) => item.code === value)
        ?.translationCode || ""
    );
  }
}
