import { Component, OnInit, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "@services/authentication.service";
import { LocalStorageService } from "@services/local-storage.service";
import { MeStoreService } from "@services/me-store.service";
import { PrimeNGConfig } from "primeng/api";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  private _meStore = inject(MeStoreService);
  private _authenService = inject(AuthenticationService);
  constructor(
    private primengConfig: PrimeNGConfig,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.translateService.addLangs(["en", "vi"]);
    const language = this.localStorageService.getData("language");
    this.translateService.setDefaultLang(language ? language : "vi");
    this.translateService.use(language ? language : "vi");
  }
}
