import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProcessTranslateService {
  public isChangedLanguage = new Subject<boolean>();
  public translationDict = new BehaviorSubject<{ [key: string]: string }>({});

  constructor(private translateService: TranslateService) {
    this.isChangedLanguage.subscribe((res) => {
      if (res) this.setTranslation();
    });
    this.isChangedLanguage.next(true);
  }

  public getTranslation() {
    return this.translationDict;
  }

  public setTranslation() {
    this.translateService
      .get(translationCode)
      .subscribe((res: { [key: string]: string }) => {
        this.translationDict?.next(res);
      });
  }
}

const translationCode: string[] = [
  "dashboard.menu",
  "dashboard.breadcrumb",
  "dashboard.general.menu",
  "dashboard.general.breadcrumb",
  "user.menu",
  "user.breadcrumb",
  "user.account.menu",
  "user.account.breadcrumb",
  "user.account.notification.create_success",
  "user.account.notification.update_success",
  "user.account.notification.delete_success",
  "user.account.notification.active_success",
  "user.end_user.menu",
  "user.end_user.breadcrumb",
  "user.privilege_manager.menu",
  "user.privilege_manager.breadcrumb",
  "user.privilege_manager.headquarter.notification.create_success",
  "user.privilege_manager.headquarter.notification.delete_success",
  "user.privilege_manager.headquarter.notification.update_success",
  "report.menu",
  "report.breadcrumb",
  "report.daily_num_vehicle.menu",
  "report.daily_num_vehicle.breadcrumb",
  "report.daily_parking_card_usage.menu",
  "report.daily_parking_card_usage.breadcrumb",
  "report.daily_revenues.menu",
  "report.daily_revenues.breadcrumb",
  "report.monthly_revenues.menu",
  "report.monthly_revenues.breadcrumb",
  "report.monthly_parking_card_usage.breadcrumb",
  "report.monthly_parking_card_usage.menu",
  "report.history.menu",
  "report.history.breadcrumb",
  "headquarter.menu",
  "headquarter.breadcrumb",
  "headquarter.headquarter_manager.menu",
  "headquarter.headquarter_manager.breadcrumb",
  "headquarter.headquarter_manager.notification.create_success",
  "headquarter.headquarter_manager.notification.update_success",
  "headquarter.headquarter_manager.notification.delete_success",
  "headquarter.headquarter_manager.notification.linking_success",
  "headquarter.headquarter_manager.notification.manage_success",
  "parking_station.menu",
  "parking_station.breadcrumb",
  "parking_station.parking_station_manager.menu",
  "parking_station.parking_station_manager.breadcrumb",
  "parking_station.parking_station_manager.notification.create_success",
  "parking_station.parking_station_manager.notification.update_success",
  "parking_station.parking_station_manager.notification.delete_success",
  "parking_station.parking_station_manager.notification.linking_success",
  "parking_card.menu",
  "parking_card.breadcrumb",
  "parking_card.parking_card_manager.menu",
  "parking_card.parking_card_manager.breadcrumb",
  "parking_card.parking_card_manager.notification.create_success",
  "parking_card.parking_card_manager.notification.update_success",
  "parking_card.parking_card_manager.notification.delete_success",
  "service.menu",
  "service.breadcrumb",
  "service.parking_fee.menu",
  "service.parking_fee.breadcrumb",
  "service.parking_fee.notification.update_success",
  "advertisement.menu",
  "advertisement.breadcrumb",
  "advertisement.simple_ad_manager.menu",
  "advertisement.simple_ad_manager.breadcrumb",
  "advertisement.simple_ad_manager.notification.create_success",
  "advertisement.simple_ad_manager.notification.update_success",
  "advertisement.simple_ad_manager.notification.delete_success",
  "notification.menu",
  "notification.breadcrumb",
  "notification.notification.menu",
  "notification.notification.breadcrumb",
  "notification.notification.notification.create_success",
  "notification.notification.notification.update_success",
  "notification.notification.notification.delete_success",
  "notification.notification.notification.push_immediate_success",
  "notification.notification.notification.push_sms",
  "me.breadcrumb",
  "me.profile.breadcrumb",
  "me.profile.profile_detail.notification.update_success",
  "common.notification.create.success",
  "common.notification.update.success",
  "common.notification.delete.success",
  "common.notification.upload.success",
  "common.type.allow_page_codes_privilege_type.view",
  "common.type.allow_page_codes_privilege_type.create",
  "common.type.allow_page_codes_privilege_type.update",
  "common.type.allow_page_codes_privilege_type.delete",
  "common.type.allow_page_codes_privilege_type.detail",
  "common.type.allow_page_codes_privilege_type.linking",
  "common.type.allow_page_codes_privilege_type.manage",
  "common.type.allow_page_codes_privilege_type.active",
  "common.type.allow_page_codes_privilege_type.push_sms",
  "common.type.allow_page_codes_privilege_type.push_immediate",
  "common.type.vehicle_type.electric_car",
  "common.type.vehicle_type.electric_bike",
  "common.type.vehicle_type.electric_motorbike",
  "common.type.vehicle_type.petrol_car",
  "common.type.vehicle_type.petrol_motorbike",
  "common.type.parking_card_type.daily_parking_card",
  "common.type.parking_card_type.monthly_parking_card",
  "common.back_end_error.1001",
  "common.back_end_error.1002",
  "common.back_end_error.1003",
  "common.back_end_error.1004",
  "common.back_end_error.1005",
  "common.back_end_error.1006",
  "common.back_end_error.1007",
  "common.back_end_error.1008",
  "common.back_end_error.1009",
  "common.back_end_error.1010",
  "common.back_end_error.1011",
  "common.back_end_error.1012",
  "common.back_end_error.1013",
  "common.back_end_error.1014",
  "common.back_end_error.1015",
  "common.back_end_error.1016",
  "common.back_end_error.2001",
  "common.back_end_error.2002",
  "common.back_end_error.3001",
  "common.back_end_error.3002",
  "common.back_end_error.3003",
  "common.back_end_error.3004",
  "common.back_end_error.3005",
  "common.back_end_error.3006",
  "common.back_end_error.3007",
  "common.back_end_error.3008",
  "common.back_end_error.3009",
  "common.back_end_error.4002",
  "common.back_end_error.5001",
  "common.back_end_error.5002",
  "common.back_end_error.5003",
  "common.back_end_error.5004",
  "common.back_end_error.5005",
  "common.back_end_error.5006",
  "common.back_end_error.5007",
  "common.back_end_error.5008",
  "common.back_end_error.6001",
  "common.back_end_error.6002",
  "common.back_end_error.6003",
  "common.back_end_error.6004",
  "common.back_end_error.7001",
  "common.back_end_error.7002",
  "common.back_end_error.7003",
  "common.back_end_error.7004",
  "common.back_end_error.7005",
  "common.back_end_error.10001",
  "common.back_end_error.10002",
];
