import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, RouterOutlet } from "@angular/router";
import { SidebarComponent } from "@layout/components/sidebar/sidebar.component";
import { RouteParamService } from "@services/route-param.service";
import { map } from "rxjs";

@Component({
  selector: "app-headquarter-layout",
  standalone: true,
  imports: [RouterOutlet, SidebarComponent],
  templateUrl: "./headquarter-layout.component.html",
  styleUrl: "./headquarter-layout.component.scss",
})
export class HeadquarterLayoutComponent implements OnInit {
  private _activatedRoute = inject(ActivatedRoute);
  private _routeParamService = inject(RouteParamService);
  private _destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this._activatedRoute.paramMap
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        map((res) => {
          const headquarterId = res.get("headquarterId") ?? null;
          return headquarterId;
        })
      )
      .subscribe((res) => {
        this._routeParamService.headquarterId_.set(res);
      });
  }
}
