import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { STATUS_CODE } from "@constants/string";
import { NOTIFICATION_TYPE } from "@constants/type";
import { CookieService } from "@services/cookie.service";
import { ProcessTranslateService } from "@services/process-translate.service";
import { ToastService } from "@services/toast.service";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private _toast = inject(ToastService);
  private _cookie = inject(CookieService);
  private _processTranslateService = inject(ProcessTranslateService);
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);

  constructor() {}

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      catchError(() => {
        this._toast.pushNotification.next({
          severity: NOTIFICATION_TYPE.WARN,
          detail: "Lỗi hệ thống",
        });
        return throwError(() => new Error("Lỗi hệ thống"));
      }),

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((res: any) => {
        if ((res?.url as string)?.includes("assets/i18n")) {
          return res;
        }

        if (res?.body?.success) {
          return res;
        }

        if (res?.body?.code == STATUS_CODE.TOKEN_EXPIRED) {
          this._cookie.deleteCookie("jwt_token");
          this._router.navigate(["/auth", "login"], {
            queryParams: {
              url: window.location.href.split(window.location.host)?.[1],
            },
          });
        }

        if (res.body?.message) {
          this._toast.pushNotification.next({
            severity: NOTIFICATION_TYPE.WARN,
            detail:
              this._processTranslateService.translationDict?.value?.[
                `common.back_end_error.${res?.body?.code}`
              ] || res?.body?.message,
          });
          throw throwError(() => new Error(res?.body?.message));
        }

        return res;
      })
    );
  }
}
