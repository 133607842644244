import { effect, inject, Injectable, signal } from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { DomSanitizer } from "@angular/platform-browser";
import { HeadquarterAccountControllerService } from "@apis/headquarter-account-controller.service";
import { NOTIFICATION_TYPE } from "@constants/type";
import { HeadquarterV2 } from "@models/headquarter";
import { RoleAssociation } from "@models/role-association";
import { EndUserAccountV2 } from "@models/user-account";
import { AuthenticationService } from "@services/authentication.service";
import { ToastService } from "@services/toast.service";
import { catchError, combineLatest, map, of, switchMap, tap } from "rxjs";

@Injectable({ providedIn: "root" })
export class MeStoreService {
  private _headquarterAccountController = inject(
    HeadquarterAccountControllerService
  );

  private _toast = inject(ToastService);
  private _authenticationService = inject(AuthenticationService);
  private _domSanitizer = inject(DomSanitizer);

  public profile_ = signal<EndUserAccountV2 | null>(null);

  // public pageUrls_ = computed(() => {
  //   return this.profile_() != null
  //     ? this._mapAllowPagesCodeArrToMyPage(
  //         this.profile_()?.allowPageCodesArray ?? []
  //       ).filter((i) => i != PAGES_V2.hubCenter.code)
  //     : null;
  // });

  // public headquarters_ = signal<HeadquarterV2[]>([]);

  public headquarters_ = toSignal<HeadquarterV2[] | null>(
    toObservable(this.profile_).pipe(
      switchMap((profile) =>
        profile
          ? this._headquarterAccountController
              .getCurrentHeadquarters()
              .pipe(map((res) => res.data))
          : of(null)
      )
    )
  );

  constructor() {
    effect(() => {
      this.profile_() == null &&
        this._authenticationService.isAuthenticated() &&
        this.fetchMeProfile();
    });

    // effect(() => {
    //   this.profile_() && this._fetchHeadquatersByAccountId();
    // });
  }

  public fetchMeProfile() {
    let profile!: EndUserAccountV2;

    return this._headquarterAccountController
      .getCurrent()
      .pipe(
        map((r) => r.data),
        tap((r) => (profile = r)),
        switchMap((profile: EndUserAccountV2) => {
          return combineLatest([
            this._headquarterAccountController
              .getRoleAssociation(profile?.headquarterId)
              .pipe(
                map((r) => r.data),
                catchError(() => of(null))
              ),

            profile?.avatarFileId
              ? this._headquarterAccountController.getCurrentAvatarFile().pipe(
                  map((r) => r.body),
                  catchError(() => of(null))
                )
              : of(null),
          ]);
        })
      )
      .subscribe(([roleAssociation, avatar]) => {
        roleAssociation &&
          this._appendRoleAssociation(roleAssociation, profile);

        avatar && this._appendAvatar(avatar, profile);

        this.profile_.set(profile);
      });
  }

  // private _fetchHeadquatersByAccountId() {
  //   const headquaterId = this.profile_()?.headquarterId;

  //   headquaterId &&
  //     this._headquarterAccountController
  //       .getCurrentHeadquarters()
  //       .pipe(map((res) => res.data))
  //       .subscribe((data) => {
  //         this.headquarters_.set(data);
  //       });
  // }

  private _appendRoleAssociation(
    roleAssociation: RoleAssociation,
    profile: EndUserAccountV2
  ) {
    if (!roleAssociation?.allowPageCodes) {
      this._toast.pushNotification.next({
        severity: NOTIFICATION_TYPE.WARN,
        detail:
          "Bạn chưa được phần quyền vào hệ thống! Liên hệ với admin để được hỗ trợ.",
      });

      profile.allowPageCodes = "";
      profile.allowPageCodesArray = [];
    } else {
      profile.allowPageCodes = roleAssociation?.allowPageCodes;
      profile.allowPageCodesArray = roleAssociation?.allowPageCodes
        ? profile.allowPageCodes.split(",")
        : [];
    }
  }

  private _appendAvatar(avatar: Blob, profile: EndUserAccountV2) {
    if (avatar) {
      const objectUrl = URL.createObjectURL(avatar);
      const url = this._domSanitizer.bypassSecurityTrustResourceUrl(objectUrl);
      profile.avatar = url;
    }
  }

  // private _mapAllowPagesCodeArrToMyPage(allowPageCodesArr: string[]) {
  //   // allowPageCodesArr-element: Example /dashboard.view
  //   const pages = allowPageCodesArr.map((i) => i.split(".")?.[0]);

  //   // delete repeat element
  //   return [...new Set(pages)];
  // }
}
