import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReportFilterComponent } from "@components/report-filter/report-filter.component";
import { TimeFilterComponent } from "@components/time-filter/time-filter.component";
import { ToastComponent } from "@components/toast/toast.component";
import { TSPTimeFilterComponent } from "@components/tsp-time-filter/tsp-time-filter.component";
import { UploadImageComponent } from "@components/upload-image/upload-image.component";
import { AccessControlDirective } from "@directives/access-control.directive";
import { EmptyValueTableDirective } from "@directives/empty-value-table.directive";
import { IsExpiredDirective } from "@directives/is-expired.directive";
import { TranslateModule } from "@ngx-translate/core";
import { ActionTypePipe } from "@pipes/action-type.pipe";
import { ArrayPipe } from "@pipes/array.pipe";
import { CheckNaNNumberPipe } from "@pipes/check-NaN-number.pipe";
import { CheckNullStringPipe } from "@pipes/check-null-string.pipe";
import { IsDisabledPipe } from "@pipes/is-disabled.pipe";
import { MoneyPipe } from "@pipes/money.pipe";
import { NotificationTargetTypePipe } from "@pipes/notification-target-type.pipe";
import { ParkingCardTypePipe } from "@pipes/parking-card-type.pipe";
import { ParkingHistoryTypePipe } from "@pipes/parking-history-type.pipe";
import { RawValuePipe } from "@pipes/raw-value.pipe";
import { TimePipe } from "@pipes/time.pipe";
import { VehicleTypePipe } from "@pipes/vehicle-type.pipe";
import { CalendarModule } from "primeng/calendar";
import { FileUploadModule } from "primeng/fileupload";
import { ImageModule } from "primeng/image";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { TooltipModule } from "primeng/tooltip";
import { PaginatorComponent } from "./components/paginator/paginator.component";
import { UploadFileComponent } from "./components/upload-file/upload-file.component";
import { ToastModule } from "primeng/toast";

const PIPES = [
  ArrayPipe,
  TimePipe,
  VehicleTypePipe,
  ParkingCardTypePipe,
  CheckNaNNumberPipe,
  MoneyPipe,
  NotificationTargetTypePipe,
  ParkingHistoryTypePipe,
  ActionTypePipe,
  CheckNullStringPipe,
  IsDisabledPipe,
  RawValuePipe,
];
const DIRECTIVES = [
  AccessControlDirective,
  EmptyValueTableDirective,
  IsExpiredDirective,
];
const COMPONENTS = [
  ToastComponent,
  TimeFilterComponent,
  PaginatorComponent,
  ReportFilterComponent,
  UploadImageComponent,
  UploadFileComponent,
  TSPTimeFilterComponent,
];
const MODULES = [
  CommonModule,
  FormsModule,
  PanelModule,
  CalendarModule,
  TranslateModule,
  PaginatorModule,
  TooltipModule,
  FileUploadModule,
  ImageModule,
  ToastModule,
];
@NgModule({
  declarations: [...PIPES, ...COMPONENTS, ...DIRECTIVES],
  imports: MODULES,
  exports: [...PIPES, ...COMPONENTS, ...DIRECTIVES],
})
export class SharedModule {}
