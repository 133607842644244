import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthenticationService } from "@services/authentication.service";

export const LoggedGuard: CanActivateFn = () => {
  if (inject(AuthenticationService).isAuthenticated()) return true;

  inject(Router).navigateByUrl("auth/login");
  return false;
};
