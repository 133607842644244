import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { environment } from "@environments/environment";
import { AuthenticationService } from "@services/authentication.service";
import { CookieService } from "@services/cookie.service";
import { Observable } from "rxjs";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private authenticationService = inject(AuthenticationService);
  private cookie = inject(CookieService);

  constructor() {}

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    httpRequest: HttpRequest<any>,
    next: HttpHandler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    if (!httpRequest.url.includes("/assets/i18n")) {
      httpRequest = httpRequest.clone({
        url: environment.apiURL + httpRequest.url,
      });
    }

    const isAuthenticated = this.authenticationService.isAuthenticated();

    if (isAuthenticated) {
      const jwt_token = this.cookie.getCookie("jwt_token");

      httpRequest = httpRequest.clone({
        setHeaders: {
          Authorization: jwt_token,
        },
      });
    }

    return next.handle(httpRequest);
  }
}
