export function updateUrl(
  keyUpdate: string,
  value: string,
  url: string
): string {
  const urlSegment = url?.split("/");
  const indexKeyUpdate = urlSegment.findIndex((i) => i == keyUpdate);

  if (indexKeyUpdate != -1) {
    urlSegment[indexKeyUpdate + 1] = value;
    return urlSegment?.join("/");
  }

  return url;
}
