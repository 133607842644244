import { Component, ElementRef } from "@angular/core";
import { LayoutService } from "../../service/app.layout.service";
import { RouterModule } from "@angular/router";
import { MenuComponent } from "@layout/components/menu/menu.component";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  standalone: true,
  imports: [RouterModule, MenuComponent],
})
export class SidebarComponent {
  constructor(
    public layoutService: LayoutService,
    public el: ElementRef
  ) {}
}
