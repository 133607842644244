import { Directive, ElementRef, OnChanges } from "@angular/core";

@Directive({
  selector: "[appEmptyValueTable]",
})
export class EmptyValueTableDirective implements OnChanges {
  constructor(private _elementRef: ElementRef) {}
  ngOnChanges(): void {
    setTimeout(() => {
      const tableElement = this._elementRef.nativeElement;
      const tdHTMLCollection = tableElement.getElementsByTagName("td");
      const listTdElement = Array.from(tdHTMLCollection);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      listTdElement?.forEach((item: any) => {
        if (!item.innerHTML.trim()) {
          item.innerHTML = "---";
        }
      });
    }, 500);
  }
}
