import { CommonModule } from "@angular/common";
import {
  Component,
  Injector,
  computed,
  effect,
  inject,
  signal,
} from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { MeStoreService } from "@services/me-store.service";
import { RouteParamService } from "@services/route-param.service";
import { updateUrl } from "@utils/update-url";
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { filter, take } from "rxjs";
import { LayoutService } from "src/app/layout/service/app.layout.service";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  standalone: true,
  imports: [DropdownModule, ButtonModule, FormsModule, CommonModule],
})
export class TopbarComponent {
  public meStore = inject(MeStoreService);
  public routeParamService = inject(RouteParamService);
  public layoutService = inject(LayoutService);
  public router = inject(Router);
  private _injector = inject(Injector);

  protected headquarters$ = toObservable(this.meStore.headquarters_);
  protected headquarterId_ = computed(() =>
    this.routeParamService.headquarterId_()
  );

  protected selectedHeadquarterId_ = signal<string | null>(null);

  constructor() {
    this.headquarters$
      .pipe(
        filter((i) => !!i && i?.length > 0),
        take(1)
      )
      .subscribe((headquarters) => {
        this.onMenuClose();

        if (
          !!this.headquarterId_() &&
          headquarters?.map((i) => i?.id)?.includes(this.headquarterId_()!)
        ) {
          this.selectedHeadquarterId_.set(this.headquarterId_());
          this.onMenuOpen();
          return;
        }

        headquarters?.[0] &&
          this.selectedHeadquarterId_.set(headquarters[0].id);
      });

    effect(() => {
      if (this.selectedHeadquarterId_()) {
        this.onMenuOpen();

        this.router.navigateByUrl(
          updateUrl(
            "headquarters",
            this.selectedHeadquarterId_()!,
            this.router.url
          )
        );
      }
    });
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onMenuOpen() {
    this.layoutService.onMenuOpen();
  }

  onMenuClose() {
    this.layoutService.onMenuClose();
  }

  onProfileButtonClick() {
    this.layoutService.showProfileSidebar();
  }
}
