<div class="w-full h-full relative">
  <div
    class="fixed w-screen h-screen flex align-items-center justify-content-center left-0"
    style="top: 6rem"
  >
    @if (headquarters_() != null) {
      <div style="margin-top: -27rem">
        <app-empty-object
          [title]="title_()"
          [message]="message_()"
          [backBtn]="backBtn_()"
        ></app-empty-object>
      </div>
    } @else {
      <div class="-mt-8"><p-progressSpinner ariaLabel="loading" /></div>
    }
  </div>
</div>
