<div class="container">
  <div
    class="picture-container"
    [ngStyle]="{
      width: width,
      height: height,
      'border-radius': type === 'AVATAR' ? '50%' : '8px'
    }"
  >
    <div
      class="picture"
      [ngStyle]="{
        width: image ? '100%' : '25%',
        height: image ? '100%' : '25%',
        'border-radius': type === 'AVATAR' ? '50%' : '8px'
      }"
    >
      <img
        [src]="image ? image : 'assets/images/plus_icon.png'"
        class="picture-src"
        id="wizardPicturePreview"
      />

      <input
        type="file"
        id="wizard-picture"
        (change)="uploadImage()"
        #file_input
      />
    </div>
  </div>
</div>
