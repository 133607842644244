import { Pipe, PipeTransform } from "@angular/core";
import { FEType } from "@models/front-end";
import { VEHICLE_TYPE } from "@constants/type";

@Pipe({
  name: "vehicleType",
})
export class VehicleTypePipe implements PipeTransform {
  transform(value?: number): string {
    if (value != 0 && !value) return "Không rõ";
    const listVehicleTypes = Object.values(VEHICLE_TYPE);

    return (
      listVehicleTypes.find((item: FEType) => item.code == value)
        ?.translationCode || "Không rõ"
    );
  }
}
