import { Injectable, inject } from "@angular/core";
import { CookieService } from "./cookie.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private _cookie = inject(CookieService);

  constructor() {}

  public isAuthenticated(): boolean {
    const token = this._cookie.getCookie("jwt_token");
    if (token) return true;
    return false;
  }
}
