import { inject, Injectable } from "@angular/core";
import { NAVS } from "@constants/navs.constant";
import { Nav } from "@interfaces/nav.interface";
import { ProcessTranslateService } from "@services/process-translate.service";
import { Subject } from "rxjs";
import { MenuChangeEvent } from "../api/menuchangeevent";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  private _processTranslate = inject(ProcessTranslateService);

  private menuSource = new Subject<MenuChangeEvent>();
  private resetSource = new Subject();

  menuSource$ = this.menuSource.asObservable();
  resetSource$ = this.resetSource.asObservable();

  onMenuStateChange(event: MenuChangeEvent) {
    this.menuSource.next(event);
  }

  public renderSidebar(headquarterId: string): Nav[] {
    const copiedNavs: Nav[] = JSON.parse(JSON.stringify(NAVS));
    for (const nav of copiedNavs) {
      this._fillTranslateToNavLabel(nav);

      nav.items?.forEach((elm) => {
        this._fillHeadquarterIdToNavRouterLink(headquarterId, elm);
        this._fillTranslateToNavLabel(elm);
      });
    }

    return copiedNavs;
  }

  reset() {
    this.resetSource.next(true);
  }

  private _fillHeadquarterIdToNavRouterLink(headquarterId: string, nav: Nav) {
    if (!nav.routerLink) return;
    const routerLink = [...nav.routerLink];

    nav.routerLink = routerLink?.map((elm) =>
      elm == ":headquarterId" ? headquarterId : elm
    );
  }

  private _fillTranslateToNavLabel(nav: Nav) {
    if (!nav.label) return;
    console.log(this._processTranslate.translationDict.value?.[nav.label]);

    nav.label =
      this._processTranslate.translationDict.value?.[nav.label] ?? nav.label;
  }
}
