import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "is_disabled",
})
export class IsDisabledPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: boolean): string {
    if (typeof value != "boolean") return "unknow";
    if (value) return "Vô hiệu hóa";
    else return "Khả dụng";
  }
}
