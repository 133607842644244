import { Pipe, PipeTransform } from "@angular/core";
import { FEType } from "@models/front-end";
import { ACTION_TYPE } from "@constants/type";

@Pipe({
  name: "action_type",
})
export class ActionTypePipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) return "";

    const listActionTypes = Object.values(ACTION_TYPE);

    return (
      listActionTypes.find((item: FEType) => item.code === value)
        ?.translationCode || ""
    );
  }
}
