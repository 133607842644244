<p-sidebar
  [(visible)]="visible"
  position="right"
  [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
  styleClass="layout-profile-sidebar w-full sm:w-25rem"
>
  <div class="flex flex-column mx-auto md:mx-0">
    <span class="mb-2 font-semibold">
      {{ "common.other.wellcome" | translate }}
    </span>
    <span class="text-color-secondary font-medium mb-5">
      {{ myProfile?.fullName }}
    </span>

    <ul class="list-none m-0 p-0">
      <li>
        <div
          class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
          (click)="onClickProfileTab()"
        >
          <span>
            <i class="pi pi-user text-xl text-primary"></i>
          </span>
          <div class="ml-3">
            <span class="mb-2 font-semibold">
              {{ "me.profile.menu" | translate }}
            </span>
          </div>
        </div>
      </li>

      <li>
        <div
          class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
          (click)="onClickHeaquarterTab()"
        >
          <span>
            <i class="pi pi-building text-xl text-primary"></i>
          </span>
          <div class="ml-3">
            <span class="mb-2 font-semibold">
              {{ "Doanh nghiệp" | translate }}
            </span>
          </div>
        </div>
      </li>

      <li>
        <div
          class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
          (click)="logout()"
        >
          <span>
            <i class="pi pi-power-off text-xl text-primary"></i>
          </span>
          <div class="ml-3">
            <span class="mb-2 font-semibold">
              {{ "me.sign_out.menu" | translate }}
            </span>
            <!-- <p class="text-color-secondary m-0">Sed ut perspiciatis</p> -->
          </div>
        </div>
      </li>
      <li>
        <p-dropdown
          id="languageDropdown"
          class="flex p-dropdown-sm"
          [options]="countries"
          [(ngModel)]="selectedCountry"
          optionLabel="name"
          [showClear]="false"
          (onChange)="changeLanguage()"
        >
          <ng-template pTemplate="selectedItem">
            <div
              class="country-item country-item-value flex align-items-center ng-star-inserted"
              *ngIf="selectedCountry"
            >
              <img
                src="assets/demo/images/flag/flag_placeholder.png"
                [class]="'mr-2 flag flag-' + selectedCountry?.code"
                style="width: 18px"
              />
              <div>{{ selectedCountry?.name }}</div>
            </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
            <div class="country-item flex align-items-center ng-star-inserted">
              <img
                src="assets/demo/images/flag/flag_placeholder.png"
                [class]="'mr-2 flag flag-' + country.code"
                style="width: 18px"
              />
              <div>{{ country.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </li>
    </ul>
  </div>
</p-sidebar>
