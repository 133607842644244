import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LocalStorageService } from "@services/local-storage.service";
import { ThemeService } from "@services/theme.service";
import { ButtonModule } from "primeng/button";
import { InputSwitchModule } from "primeng/inputswitch";
import { RadioButtonModule } from "primeng/radiobutton";
import { SidebarModule } from "primeng/sidebar";
import { LayoutService } from "../../service/app.layout.service";
import { MenuService } from "../../service/app.menu.service";

@Component({
  selector: "app-config",
  templateUrl: "./config.component.html",
  standalone: true,
  imports: [
    SidebarModule,
    CommonModule,
    RadioButtonModule,
    FormsModule,
    InputSwitchModule,
    ButtonModule,
  ],
})
export class ConfigComponent implements OnInit {
  @Input() minimal = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentThemes: any[] = [];

  scales: number[] = [12, 13, 14, 15, 16];

  constructor(
    public layoutService: LayoutService,
    public menuService: MenuService,
    private localStorageService: LocalStorageService,
    private themeService: ThemeService
  ) {}

  get visible(): boolean {
    return this.layoutService.state.configSidebarVisible;
  }

  set visible(_val: boolean) {
    this.layoutService.state.configSidebarVisible = _val;
  }

  get scale(): number {
    return this.layoutService.config.scale;
  }

  set scale(_val: number) {
    this.layoutService.config.scale = _val;
  }

  get menuMode(): string {
    return this.layoutService.config.menuMode;
  }

  set menuMode(_val: string) {
    this.layoutService.config.menuMode = _val;
    if (this.layoutService.isSlim() || this.layoutService.isHorizontal()) {
      this.menuService.reset();
    }
  }

  get colorScheme(): string {
    return this.layoutService.config.colorScheme;
  }

  set colorScheme(_val: string) {
    this.changeColorScheme(_val);
  }

  get inputStyle(): string {
    return this.layoutService.config.inputStyle;
  }

  set inputStyle(_val: string) {
    this.layoutService.config.inputStyle = _val;
  }

  get ripple(): boolean {
    return this.layoutService.config.ripple;
  }

  set ripple(_val: boolean) {
    this.layoutService.config.ripple = _val;
  }

  get menuTheme(): string {
    return this.layoutService.config.menuTheme;
  }

  set menuTheme(_val: string) {
    this.layoutService.config.menuTheme = _val;
  }

  ngOnInit() {
    this.componentThemes = [
      { name: "indigo", color: "#6366F1" },
      { name: "blue", color: "#3B82F6" },
      { name: "purple", color: "#8B5CF6" },
      { name: "teal", color: "#14B8A6" },
      { name: "cyan", color: "#06b6d4" },
      { name: "green", color: "#10b981" },
      { name: "orange", color: "#f59e0b" },
      { name: "pink", color: "#d946ef" },
    ];
    this.initTheme();
  }

  initTheme() {
    let theme;
    if ((theme = this.localStorageService.getData("theme")))
      this.changeTheme(theme);
    else this.changeTheme("green");
  }

  onConfigButtonClick() {
    this.layoutService.showConfigSidebar();
  }

  changeColorScheme(colorScheme: string) {
    const themeLink = <HTMLLinkElement>document.getElementById("theme-link");
    const themeLinkHref = themeLink.getAttribute("href");
    const currentColorScheme = "theme-" + this.layoutService.config.colorScheme;
    const newColorScheme = "theme-" + colorScheme;
    const newHref = themeLinkHref?.replace(currentColorScheme, newColorScheme);

    if (newHref)
      this.replaceThemeLink(newHref, () => {
        this.layoutService.config.colorScheme = colorScheme;
        this.layoutService.onConfigUpdate();
      });
  }

  changeTheme(theme: string) {
    this.localStorageService.saveData("theme", theme);
    this.themeService.isRefreshTheme.next(true);
    const themeLink = <HTMLLinkElement>document.getElementById("theme-link");
    const newHref = themeLink
      ?.getAttribute("href")
      ?.replace(this.layoutService.config.theme, theme);

    if (newHref)
      this.replaceThemeLink(newHref, () => {
        this.layoutService.config.theme = theme;
        this.layoutService.onConfigUpdate();
      });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  replaceThemeLink(href: string, onComplete: Function) {
    const id = "theme-link";
    const themeLink = <HTMLLinkElement>document.getElementById("theme-link");
    const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

    cloneLinkElement.setAttribute("href", href);
    cloneLinkElement.setAttribute("id", id + "-clone");

    themeLink.parentNode?.insertBefore(cloneLinkElement, themeLink.nextSibling);

    cloneLinkElement.addEventListener("load", () => {
      themeLink.remove();
      cloneLinkElement.setAttribute("id", id);
      onComplete();
    });
  }

  decrementScale() {
    this.scale--;
    this.applyScale();
  }

  incrementScale() {
    this.scale++;
    this.applyScale();
  }

  applyScale() {
    document.documentElement.style.fontSize = this.scale + "px";
  }
}
