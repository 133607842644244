<div class="w-full h-full">
  <div class="text-center p-2">
    <div>
      <div>
        <img
          src="assets/layout/images/empty-object.png"
          alt=""
          class="max-w-25rem sm:max-w-30rem"
        />
      </div>
      <div class="text-5xl font-bold" style="margin-top: -5rem">
        {{ title_() }}
      </div>
      <div
        style="max-width: 40rem; margin: 1rem auto"
        class="text-2xl text-black-alpha-50"
      >
        {{ message_() }}
      </div>

      @if (backBtn_()) {
        <button
          pButton
          pRipple
          [class]="
            'p-button p-button-primary font-bold text-black' +
            (this.backBtn_()?.class ?? '')
          "
          [label]="backBtn_()?.label ?? ''"
          (click)="goToBackUrl(backBtn_()?.url ?? '')"
        ></button>
      }
    </div>
  </div>
</div>
