import { CommonModule } from "@angular/common";
import {
  Component,
  computed,
  inject,
  OnInit,
  signal,
  Signal,
} from "@angular/core";
import { Nav } from "@interfaces/nav.interface";
import { MenuitemComponent } from "@layout/components/menu-item/menu-item.component";
import { MenuService } from "@layout/service/app.menu.service";
import { MeStoreService } from "@services/me-store.service";
import { RouteParamService } from "@services/route-param.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  standalone: true,
  imports: [MenuitemComponent, CommonModule],
})
export class MenuComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _meStore = inject(MeStoreService);
  private _routeParam = inject(RouteParamService);
  private _menuService = inject(MenuService);

  public navs_: Signal<Nav[]> = signal([]);

  public profile_ = computed(() => this._meStore.profile_());

  ngOnInit() {
    this.navs_ = computed(() =>
      this._routeParam.headquarterId_()
        ? this._menuService.renderSidebar(this._routeParam.headquarterId_()!)
        : []
    );
  }
}
