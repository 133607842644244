import { CommonModule } from "@angular/common";
import { Component, input } from "@angular/core";
import { EmptyObjectComponent } from "@components/empty-object/empty-object.component";

@Component({
  selector: "app-empty-menu-page",
  standalone: true,
  imports: [CommonModule, EmptyObjectComponent],
  templateUrl: "./empty-menu-page.component.html",
  styleUrl: "./empty-menu-page.component.scss",
})
export class EmptyMenuPageComponent {
  title_ = input<string>("", { alias: "title" });
  message_ = input<string>("", { alias: "message" });
  backBtn_ = input<{ url: string; label: string; class?: string } | null>(
    null,
    {
      alias: "backBtn",
    }
  );
}
