import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Filter } from "@models/front-end";
import { TIME_TYPE } from "@constants/type";
import { convertArrayFilterToStringFilter } from "@utils/function";
import moment from "moment";
import { CalendarTypeView } from "primeng/calendar";

@Component({
  selector: "app-tsp-time-filter",
  templateUrl: "./tsp-time-filter.component.html",
  styleUrls: ["./tsp-time-filter.component.scss"],
})
export class TSPTimeFilterComponent implements OnInit {
  public TIME_TYPE = TIME_TYPE;

  @Input() time: { start: Date; end: Date } = {
    start: new Date(),
    end: new Date(),
  };
  @Input() dateFormat = "dd-mm-yy";
  @Input() view: CalendarTypeView = "date";
  @Input() stepMinute = 5;
  @Output() getTimeFilter = new EventEmitter<string>();

  ngOnInit(): void {
    this.emitTime();
  }

  onChangeTimeFilter(event: Date, type: string) {
    const value = event;
    if (type == this.TIME_TYPE.START.code) {
      this.time.start = value;
    } else this.time.end = value;
    this.emitTime();
  }

  emitTime() {
    const arrayFilter: Filter[] = [
      {
        field: "allowed_in_at",
        operator: ">=",
        value: moment(this.time.start).startOf("day").valueOf(),
      },
      {
        field: "allowed_in_at",
        operator: "<=",
        value: moment(this.time.end).endOf("day").valueOf(),
      },
    ];
    this.getTimeFilter.emit(convertArrayFilterToStringFilter(arrayFilter));
  }
}
