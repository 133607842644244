<p-panel
  [header]="'common.filter.time_filter.title' | translate"
  [toggleable]="true"
>
  <ng-template pTemplate="icons">
    <ng-content select="[advanceFilter]"></ng-content>
  </ng-template>
  <div class="grid formgrid">
    <div class="field col-6">
      <label for="name">
        {{ "common.filter.time_filter.start_date" | translate }}
      </label>
      <p-calendar
        inputId="startTime"
        class="w-full"
        appendTo="body"
        [view]="view"
        [stepMinute]="stepMinute"
        [ngModel]="time.start"
        [dateFormat]="dateFormat"
        [pTooltip]="
          'common.filter.time_filter.tooltip.choose_start_date' | translate
        "
        tooltipPosition="top"
        (onSelect)="onChangeTimeFilter($event, TIME_TYPE.START.code)"
        [showIcon]="true"
      ></p-calendar>
    </div>
    <div class="field col-6">
      <label for="name">
        {{ "common.filter.time_filter.end_date" | translate }}
      </label>
      <p-calendar
        inputId="endTime"
        class="w-full"
        appendTo="body"
        [view]="view"
        [stepMinute]="stepMinute"
        [ngModel]="time.end"
        [dateFormat]="dateFormat"
        [pTooltip]="
          'common.filter.time_filter.tooltip.choose_end_date' | translate
        "
        tooltipPosition="top"
        (onSelect)="onChangeTimeFilter($event, TIME_TYPE.END.code)"
        [showIcon]="true"
      ></p-calendar>
    </div>
  </div>
  <ng-content select="[otherFilter]"></ng-content>
</p-panel>
