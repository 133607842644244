import { Pipe, PipeTransform } from "@angular/core";
import { TARGET_TYPE } from "@constants/type";

@Pipe({
  name: "notification_target_type",
})
export class NotificationTargetTypePipe implements PipeTransform {
  transform(targetEndUser: string): string {
    if (!targetEndUser) return "";

    if (targetEndUser?.includes("parkingStationId"))
      return TARGET_TYPE["PARKING_STATION"].translationCode;
    return TARGET_TYPE["USER"].translationCode;
  }
}
