<p-panel
  [header]="'common.filter.report_filter.title' | translate"
  [toggleable]="true"
>
  <ng-template pTemplate="icons">
    <ng-content select="[advanceFilter]"></ng-content>
  </ng-template>
  <div class="grid formgrid">
    <div class="field col-12 sm:col-4 lg:col-12">
      <label for="name">
        {{ "common.filter.report_filter.start_date" | translate }}
      </label>
      <p-calendar
        inputId="startTime"
        class="w-full"
        appendTo="body"
        [view]="view"
        [stepMinute]="stepMinute"
        [ngModel]="time.start"
        [dateFormat]="dateFormat"
        (onSelect)="onChangeTimeFilter($event, TIME_TYPE.START.code)"
        [pTooltip]="
          'common.filter.report_filter.tooltip.choose_start_date' | translate
        "
        tooltipPosition="top"
        [showIcon]="true"
      ></p-calendar>
    </div>
    <div class="field col-12 sm:col-4 lg:col-12">
      <label for="name">
        {{ "common.filter.report_filter.end_date" | translate }}
      </label>
      <p-calendar
        inputId="endTime"
        class="w-full"
        appendTo="body"
        [view]="view"
        [stepMinute]="stepMinute"
        [ngModel]="time.end"
        [dateFormat]="dateFormat"
        (onSelect)="onChangeTimeFilter($event, TIME_TYPE.END.code)"
        [pTooltip]="
          'common.filter.report_filter.tooltip.choose_end_date' | translate
        "
        tooltipPosition="top"
        [showIcon]="true"
      ></p-calendar>
    </div>
    <div class="field col-12 sm:col-4 lg:col-12">
      <label for="name">
        {{ "common.filter.report_filter.parking_station" | translate }}
      </label>
      <p-dropdown
        [placeholder]="
          'common.placeholder.choose_one_parking_station' | translate
        "
        [options]="listParkingStations"
        optionLabel="name"
        [ngModel]="selectedParkingStation"
        (ngModelChange)="onChangeParkingStation($event)"
        [pTooltip]="
          'common.filter.report_filter.tooltip.choose_parking_station'
            | translate
        "
        tooltipPosition="top"
      ></p-dropdown>
    </div>
  </div>
</p-panel>
