/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { Toast } from "@models/front-end";
import { NOTIFICATION_TYPE } from "@constants/type";
import { ToastService } from "@services/toast.service";

@Component({
  selector: "app-upload-image",
  templateUrl: "./upload-image.component.html",
  styleUrls: ["./upload-image.component.scss"],
})
export class UploadImageComponent {
  @Input() image: any;
  @Input() width!: string;
  @Input() height!: string;
  @Input() type!: string;
  @Input() fileTypes = /(\.jpg|\.jpeg|\.png)$/i;
  @Output() get_image: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("file_input") file_input: ElementRef = new ElementRef<any>("a");
  preview: any = document.getElementById("wizardPicturePreview");

  constructor(private toastService: ToastService) {
    this.uploadImage = this.uploadImage.bind(this);
    this.preview = document.getElementById("wizardPicturePreview");
  }

  uploadImage(): void {
    const img = document.getElementById("wizard-picture");
    const filePath = this.file_input?.nativeElement.value;

    if (!this.fileTypes.exec(filePath)) {
      const notification: Toast = {
        severity: NOTIFICATION_TYPE.SUCCESS,
        detail: "Sai định dạng",
      };
      this.toastService.pushNotification.next(notification);
      this.file_input.nativeElement.value = "";
      return;
    } else {
      if (
        this.file_input.nativeElement.files &&
        this.file_input.nativeElement.files[0]
      ) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          if (typeof e.target.result == "string") {
            const result = e.target.result;
            this.image = e.target.result;
            this.get_image.emit(result);
            if (img) img.style.display = "block";
          }
        };

        reader.readAsDataURL(this.file_input.nativeElement.files[0]);
      }
    }
  }
}
