import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthenticationService } from "@services/authentication.service";

export const NotLoggedGuard: CanActivateFn = () => {
  if (!inject(AuthenticationService).isAuthenticated()) return true;
  else {
    inject(Router).navigateByUrl("");
    return false;
  }
};
