import { CommonModule } from "@angular/common";
import { Component, inject, input } from "@angular/core";
import { Router } from "@angular/router";
import { ButtonModule } from "primeng/button";

@Component({
  selector: "app-empty-object",
  standalone: true,
  imports: [ButtonModule, CommonModule],
  templateUrl: "./empty-object.component.html",
  styleUrl: "./empty-object.component.scss",
})
export class EmptyObjectComponent {
  title_ = input<string>("", { alias: "title" });
  message_ = input<string>("", { alias: "message" });
  backBtn_ = input<{ url: string; label: string; class?: string } | null>(
    null,
    {
      alias: "backBtn",
    }
  );

  private _router = inject(Router);

  goToBackUrl(url: string) {
    this._router.navigateByUrl(url);
  }
}
