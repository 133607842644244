import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  effect,
  inject,
} from "@angular/core";
import { MeStoreService } from "@services/me-store.service";

@Directive({
  selector: "[accessControl]",
})
export class AccessControlDirective implements OnInit {
  @Input() page = "";
  @Input() privilegeType: string | number | boolean = "";

  private _meStore = inject(MeStoreService);

  constructor(private _elementRef: ElementRef) {
    effect(() => {
      this._meStore.profile_();
      this.checkAccess();
    });
  }

  ngOnInit(): void {
    // this.defaultDisplayAttributeValue =
    // this._elementRef.nativeElement.style.display;
    this._elementRef.nativeElement.classList.add("hidden");
  }

  checkAccess() {
    let isAccessControl = false;

    if (this.privilegeType && this.page) {
      const allowPageCode = `${this.page}.${this.privilegeType}`;
      if (this._meStore.profile_()?.allowPageCodes.includes(allowPageCode)) {
        isAccessControl = true;
      } else isAccessControl = false;
    }

    if (isAccessControl)
      this._elementRef.nativeElement.classList.remove("hidden");
  }
}
