import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "check_null_string",
})
export class CheckNullStringPipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) return "---";
    return value;
  }
}
