import { Pipe, PipeTransform } from "@angular/core";
import { FEType } from "@models/front-end";
import { PARKING_HISTORY_TYPE } from "@constants/type";

@Pipe({
  name: "parking_history_type",
})
export class ParkingHistoryTypePipe implements PipeTransform {
  transform(value?: number): string {
    if (value != 0 && !value) return "";

    const listParkingHistoryTypes = Object.values(PARKING_HISTORY_TYPE);

    return (
      listParkingHistoryTypes.find((item: FEType) => item.code === value)
        ?.translationCode ?? ""
    );
  }
}
