<div class="relative flex gap-2 align-items-center">
  <p-button icon="pi pi-upload"></p-button>
  <input
    type="file"
    name="photo"
    id="upload-photo"
    style="
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    "
    (change)="onUpload($event)"
  />

  <p-image
    alt="Image"
    [width]="width"
    [height]="height"
    [preview]="true"
    [src]="this.url"
    *ngIf="this.url"
  ></p-image>

  <div class="font-semibold" *ngIf="isShowFileName">{{ file?.name }}</div>
</div>
