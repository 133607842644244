import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "array",
})
export class ArrayPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any[], arg?: string): string {
    if (!value) return "";
    if (!arg) return value.join(", ");
    return value.map((item) => item?.[arg]).join(", ");
  }
}
