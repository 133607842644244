import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";

@Directive({
  selector: "[appIsExpired]",
})
export class IsExpiredDirective implements OnInit, OnChanges {
  @Input() isExpired!: number;
  constructor(private _elementRef: ElementRef) {}

  ngOnInit(): void {
    this._elementRef.nativeElement.classList.add("text-black");
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.["isExpired"].currentValue) {
      const nowTime = new Date().getTime();

      if (this.isExpired) {
        if (nowTime <= this.isExpired) {
          this._elementRef.nativeElement.classList.remove("text-black");
          this._elementRef.nativeElement.classList.add("text-green-500");
        } else {
          this._elementRef.nativeElement.classList.remove("text-black");
          this._elementRef.nativeElement.classList.add("text-red-500");
        }
      } else {
        this._elementRef.nativeElement.classList.remove("text-black");
        this._elementRef.nativeElement.classList.add("text-red-500");
      }
    }
  }
}
