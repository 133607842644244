/* eslint-disable @typescript-eslint/no-explicit-any */
import { FEType, PageCode } from "@models/front-end";

export const NOTIFICATION_TYPE = {
  WARN: "warn",
  INFO: "info",
  SUCCESS: "success",
  ERROR: "error",
};

export const PAGES: { [key: string]: PageCode } = {
  generalDashboard: {
    code: "/dashboard/general",
    translationCode: "dashboard.general.menu",
    listPrivileges: ["view"],
  },
  deviceDashboard: {
    code: "/dashboard/device",
    translationCode: "Thiết bị",
    listPrivileges: ["view"],
  },
  headquarter: {
    code: "/headquarter",
    translationCode: "headquarter.headquarter_manager.menu",
    listPrivileges: ["view", "create", "update", "delete", "linking", "manage"],
  },
  userVip: {
    code: "/user/vip",
    translationCode: "Vip",
    listPrivileges: ["view", "create", "delete", "detail"],
  },
  userAccount: {
    code: "/user/account-manager",
    translationCode: "user.account.menu",
    listPrivileges: ["view", "create", "update", "delete"],
  },
  userEndUser: {
    code: "/user/end-user",
    translationCode: "user.end_user.menu",
    listPrivileges: ["view", "active", "detail"],
  },
  allowPageCodes: {
    code: "/user/allow-page-codes",
    translationCode: "user.privilege_manager.menu",
    listPrivileges: ["view"],
  },
  softwareAccount: {
    code: "/user/software-account",
    translationCode: "Tài khoản phần mềm",
    listPrivileges: ["view", "create", "delete"],
  },
  parkingStationLicense: {
    code: "/license/parking-station",
    translationCode: "License bãi đậu xe",
    listPrivileges: ["view", "create", "delete", "detail"],
  },
  vehicleIn: {
    code: "/report/vehicle-in",
    translationCode: "Báo cáo xe còn trong bãi",
    listPrivileges: ["view", "detail"],
  },

  // dailyNumVehicle: {
  //     code: '/report/daily-num-vehicle',
  //     translationCode: 'report.daily_num_vehicle.menu',
  //     listPrivileges: ['view'],
  // },
  // dailyParkingCardUsage: {
  //     code: '/report/daily-parking-card-usage',
  //     translationCode: 'report.daily_parking_card_usage.menu',
  //     listPrivileges: ['view'],
  // },
  // monthlyParkingCardUsage: {
  //     code: '/report/monthly-parking-card-usage',
  //     translationCode: 'report.monthly_parking_card_usage.menu',
  //     listPrivileges: ['view'],
  // },
  registeredMonthlyParkingCard: {
    code: "/report/registered-monthly-parking-card",
    translationCode: "Báo cáo thẻ tháng đã đăng kí",
    listPrivileges: ["view", "detail"],
  },
  dailyRevenues: {
    code: "/report/daily-revenues",
    translationCode: "report.daily_revenues.menu",
    listPrivileges: ["view"],
  },
  monthlyRevenues: {
    code: "/report/monthly-revenues",
    translationCode: "report.monthly_revenues.menu",
    listPrivileges: ["view"],
  },
  // history: {
  //     code: '/report/history',
  //     translationCode: 'report.history.menu',
  //     listPrivileges: ['view','detail'],
  // },
  parkingSession: {
    code: "/report/parking-session",
    translationCode: "Lịch sử vào ra",
    listPrivileges: ["view", "detail"],
  },
  abnormalHistory: {
    code: "/report/abnormal-history",
    translationCode: "Báo cáo hành động bất thường",
    listPrivileges: ["view", "detail"],
  },
  parkingStationManager: {
    code: "/parking-station",
    translationCode: "parking_station.parking_station_manager.menu",
    listPrivileges: ["view", "create", "update", "delete", "detail"],
  },
  parkingCardManager: {
    code: "/parking-card",
    translationCode: "parking_card.parking_card_manager.menu",
    listPrivileges: ["view", "create", "update", "delete", "active", "detail"],
  },
  parkingFee: {
    code: "/service/parking-fee",
    translationCode: "service.parking_fee.menu",
    listPrivileges: ["update"],
  },
  simpleAdManager: {
    code: "/advertisement/simple-ad-manager",
    translationCode: "advertisement.simple_ad_manager.menu",
    listPrivileges: ["view", "create", "update", "delete"],
  },
  notification: {
    code: "/notification",
    translationCode: "notification.notification.menu",
    listPrivileges: [
      "view",
      "create",
      "update",
      "delete",
      "pushSMS",
      "pushImmediate",
    ],
  },
  gate: {
    code: "/gate",
    translationCode: "Cổng",
    listPrivileges: ["view", "create", "update", "delete", "detail"],
  },
  device: {
    code: "/device",
    translationCode: "Thiết bị",
    listPrivileges: ["view", "create", "update", "delete"],
  },
};

export const PRIVILEGE_PAGE_TYPE = {
  ALLOW_PAGE_CODES: {
    translationCode: "Phân quyền theo trang",
  },
  HEADQUARTER: {
    translationCode: "Phân quyền theo doanh nghiệp",
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ACTIVATE_STATUS_PARKING_CARD_TYPE: { [key: string]: any } = {
  ALL: {
    code: -1,
    translationCode: "common.type.status_parking_card_type.all",
  },
  WAIT_FOR_ACTIVATE: {
    code: 0,
    translationCode: "common.type.status_parking_card_type.wait_for_activate",
  },
  ACTIVATED: {
    code: 1,
    translationCode: "common.type.status_parking_card_type.activated",
  },
};

export const PARKING_CARD_MANAGER_TYPE: { [key: string]: any } = {
  belong_to_parking_station_and_headquarter: {
    translationCode:
      "parking_card.parking_card_manager.type.belong_to_parking_station_and_headquarter.title",
  },
  belong_to_parking_station: {
    translationCode:
      "parking_card.parking_card_manager.type.belong_to_independent_parking_station.title",
  },
  belong_to_headquarter: {
    translationCode:
      "parking_card.parking_card_manager.type.belong_to_headquarter.title",
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PARKING_STATION_MANAGER_TYPE: { [key: string]: any } = {
  belong_to_headquarter: {
    translationCode:
      "parking_station.parking_station_manager.type.belong_to_headquarter.title",
  },
  independent: {
    translationCode:
      "parking_station.parking_station_manager.type.independent.title",
  },
};

export const PARKING_CARD_TYPE: { [key: string]: FEType } = {
  dailyParkingCard: {
    code: 0,
    translationCode: "common.type.parking_card_type.daily_parking_card",
  },
  monthlyParkingCard: {
    code: 1,
    translationCode: "common.type.parking_card_type.monthly_parking_card",
  },
};

export const VEHICLE_TYPE: { [key: string]: FEType } = {
  petrolCar: {
    code: 1,
    translationCode: "common.type.vehicle_type.petrol_car",
  },
  petrolMotorbike: {
    code: 0,
    translationCode: "common.type.vehicle_type.petrol_motorbike",
  },
  // electricMotorbike: {
  //     code: 10,
  //     translationCode: 'common.type.vehicle_type.electric_motorbike',
  // },
  // electricCar: {
  //     code: 11,
  //     translationCode: 'common.type.vehicle_type.electric_car',
  // },
  // electricBike: {
  //     code: 12,
  //     translationCode: 'common.type.vehicle_type.electric_bike',
  // },
};

export const DATE_DISPLAY_TYPE = {
  FULL: {
    code: "FULL",
  },
  DATE: {
    code: "DATE",
  },
  TIME: {
    code: "TIME",
  },
};

export const TIME_TYPE = {
  START: {
    code: "START",
  },
  END: {
    code: "END",
  },
};

export const USING_STATUS = {
  IN: {
    code: 0,
    translationCode: "Xe đang trong bãi",
  },
  OUT: {
    code: 1,
    translationCode: "Xe đã ra khỏi bãi",
  },
  LOST: {
    code: 2,
    translationCode: "Xe báo mất",
  },
};

export const TARGET_TYPE: { [key: string]: FEType } = {
  PARKING_STATION: {
    code: "PARKING_STATION",
    translationCode: "Theo bãi đậu xe",
  },
  USER: {
    code: "USER",
    translationCode: "Theo từng người dùng",
  },
};

export const PARKING_HISTORY_TYPE: { [key: string]: FEType } = {
  UNKNOWN: {
    code: -1,
    translationCode: "Không rõ",
  },
  IN: {
    code: 0,
    translationCode: "Phiên vào",
  },
  OUT: {
    code: 1,
    translationCode: "Phiên ra",
  },
};

export const ACTION_TYPE: { [key: string]: FEType } = {
  CONFIRM: {
    code: "CONFIRM",
    translationCode: "Xác nhận",
  },
  EDIT_PLATE: {
    code: "EDIT_PLATE",
    translationCode: "Chỉnh sửa biển số",
  },
  PLATE: {
    code: "PLATE",
    translationCode: "Biển số",
  },
  NEAR_CARD: {
    code: "NEAR_CARD",
    translationCode: "Quẹt thẻ",
  },
  FAR_CARD: {
    code: "FAR_CARD",
    translationCode: "Nhận tín hiệu thẻ xa",
  },
  EDIT_PLATE_HISTORY: {
    code: "EDIT_PLATE_HISTORY",
    translationCode: "Chỉnh sửa biển số trong lịch sử",
  },
  FORCED_CHECKOUT: {
    code: "FORCED_CHECKOUT",
    translationCode: "Cho ra cưỡng bức",
  },
};
