import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "time",
})
export class TimePipe implements PipeTransform {
  transform(value?: number, ...args: unknown[]): string {
    if (!value) return "";
    else {
      const date = new Date(value);
      if (args[0] == "date")
        return `${
          date.getDate() == 0
            ? "00"
            : date.getDate() < 10
              ? "0" + date.getDate()
              : date.getDate()
        }/${
          date.getMonth() + 1 == 0
            ? "00"
            : date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1
        }/${date.getFullYear()}`;
      else {
        return `${
          date.getHours() == 0
            ? "00"
            : date.getHours() < 10
              ? "0" + date.getHours()
              : date.getHours()
        }:${
          date.getMinutes() == 0
            ? "00"
            : date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes()
        }:${
          date.getSeconds() == 0
            ? "00"
            : date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds()
        } - ${
          date.getDate() == 0
            ? "00"
            : date.getDate() < 10
              ? "0" + date.getDate()
              : date.getDate()
        }/${
          date.getMonth() + 1 == 0
            ? "00"
            : date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1
        }/${date.getFullYear()}`;
      }
    }
  }
}
