import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { InfoPaginator } from "@models/front-end";
import { Paginator, PaginatorState } from "primeng/paginator";
import { Subject } from "rxjs";

@Component({
  selector: "app-paginator",
  templateUrl: "./paginator.component.html",
  styleUrls: ["./paginator.component.scss"],
})
export class PaginatorComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild("paginator", { static: true }) paginator!: Paginator;
  @Input() goToFirstPage = new Subject<boolean>();
  @Input() pageInfo: InfoPaginator | null = null;
  @Input() amountData = 0;

  @Output() getPageInfo = new EventEmitter<InfoPaginator>();
  public totalRecordFaker: number = 5 + 1;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["amountData"]?.currentValue !== undefined ||
      changes["pageInfo"]?.currentValue !== undefined
    ) {
      this.pageInfo &&
        (this.amountData < this.pageInfo?.size
          ? (this.totalRecordFaker =
              (this.pageInfo?.page + 1) * this.pageInfo?.size)
          : (this.totalRecordFaker =
              (this.pageInfo?.page + 1) * this.pageInfo?.size + 1));
    }
  }

  ngAfterViewInit(): void {
    this.paginator?.changePage(this.pageInfo?.page ?? 0);
  }

  ngOnInit(): void {
    this.goToFirstPage.subscribe(() => {
      if (this.paginator) {
        this.paginator.changePage(0);
        const pageInfo = {
          page: 0,
          size: this.pageInfo?.size || 5,
        };
        this.pageInfo = {
          ...pageInfo,
        };
        this.getPageInfo.emit(pageInfo);
      }
    });

    // const pageInfo = {
    //   page: this.pageInfo?.page ?? 0,
    //   size: this.pageInfo?.size ?? 5,
    // };
    // this.getPageInfo.emit(pageInfo);
  }

  onPageChange(value: PaginatorState) {
    console.log(value);
    if (this.pageInfo?.size != value.rows) {
      this.pageInfo = {
        page: 0,
        size: value.rows ?? 0,
      };
      this.getPageInfo.emit(this.pageInfo);
    } else {
      this.pageInfo = {
        page: value.page ?? 0,
        size: value.rows ?? 0,
      };
      this.getPageInfo.emit(this.pageInfo);
    }
  }
}
