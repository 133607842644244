import { NgModule } from "@angular/core";
import {
  RouterModule,
  Routes,
  provideRouter,
  withComponentInputBinding,
} from "@angular/router";
import { NotfoundComponent } from "@domains/auth/notfound/notfound.component";
import { IsOwnerHeadquarterIdGuard } from "@guards/is-owner-headquarter-id.guard";
import { LoggedGuard } from "@guards/logged.guard";
import { NotLoggedGuard } from "@guards/not-logged.guard";
import { CurrentUserLayoutComponent } from "@layout/components/current-user-layout/current-user-layout.component";
import { HeadquarterLayoutComponent } from "@layout/components/headquarter-layout/headquarter-layout.component";
import { EmptyHeadquarterPageComponent } from "@pages/empty-headquarter-page/empty-headquarter-page.component";
import { EmptyMenuPageComponent } from "@pages/empty-menu-page/empty-menu-page.component";
import { AppLayoutComponent } from "./layout/components/layout/layout.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "headquarters",
    pathMatch: "full",
  },
  {
    path: "auth",
    data: { breadcrumb: "Auth" },
    children: [
      {
        path: "",
        redirectTo: "login",
        pathMatch: "full",
      },
      {
        path: "login",
        loadComponent: () =>
          import("./pages/login-page/login-page.component").then(
            (c) => c.LoginPageComponent
          ),
        canActivate: [NotLoggedGuard],
      },
    ],
  },
  {
    path: "headquarters",
    component: AppLayoutComponent,
    canActivate: [LoggedGuard],
    children: [
      {
        path: "",
        component: EmptyHeadquarterPageComponent,
        data: {
          title: "Không thấy headquarter",
          message:
            "Vui lòng chọn một headquarter ở góc phải màn hình để bắt đầu sử dụng dashboard",
        },
      },
      {
        path: ":headquarterId",
        component: HeadquarterLayoutComponent,
        canActivate: [IsOwnerHeadquarterIdGuard],
        children: [
          {
            path: "",
            component: EmptyMenuPageComponent,
            data: {
              title: "Không thấy menu",
              message: "Vui lòng chọn menu bên trái để sử dụng dashboard",
            },
          },
          {
            path: "dashboard",
            title: "dashboard",
            data: { breadcrumb: "dashboard.breadcrumb" },
            children: [
              {
                path: "general",
                loadComponent: () =>
                  import(
                    "./pages/dashboard-general-page/dashboard-general-page.component"
                  ).then((c) => c.DashboardGeneralPageComponent),
              },
              {
                path: "device",
                loadComponent: () =>
                  import(
                    "./pages/dashboard-device-page/dashboard-device-page.component"
                  ).then((c) => c.DashboardDevicePageComponent),
              },
            ],
          },
          {
            path: "report",
            data: { breadcrumb: "report.breadcrumb" },
            children: [
              {
                path: "parking-sessions",
                loadComponent: () =>
                  import(
                    "./pages/page-report-parking-sessions/page-report-parking-sessions.component"
                  ).then((c) => c.PageReportParkingSessionsComponent),
              },
              {
                path: "parking-sessions/detail",
                loadComponent: () =>
                  import(
                    "./pages/page-report-parking-sessions-detail/page-report-parking-sessions-detail.component"
                  ).then((c) => c.PageReportParkingSessionsDetailComponent),
              },
              {
                path: "vehicle-in",
                loadComponent: () =>
                  import(
                    "./pages/page-report-vehicle-in/page-report-vehicle-in.component"
                  ).then((c) => c.PageReportVehicleInComponent),
              },
              {
                path: "vehicle-in/detail",
                loadComponent: () =>
                  import(
                    "./pages/page-report-vehicle-in-detail/page-report-vehicle-in-detail.component"
                  ).then((c) => c.PageReportVehicleInDetailComponent),
              },
              {
                path: "registered-monthly-parking-cards",
                loadComponent: () =>
                  import(
                    "./pages/page-report-registered-monthly-parking-cards/page-report-registered-monthly-parking-cards.component"
                  ).then(
                    (c) => c.PageReportRegisteredMonthlyParkingCardsComponent
                  ),
              },
              {
                path: "registered-monthly-parking-cards/detail",
                loadComponent: () =>
                  import(
                    "./pages/page-report-registered-monthly-parking-cards-detail/page-report-registered-monthly-parking-cards-detail.component"
                  ).then(
                    (c) =>
                      c.PageReportRegisteredMonthlyParkingCardsDetailComponent
                  ),
              },
              {
                path: "daily-revenues",
                loadComponent: () =>
                  import(
                    "./pages/page-report-daily-revenues/page-report-daily-revenues.component"
                  ).then((c) => c.PageReportDailyRevenuesComponent),
              },
              {
                path: "monthly-revenues",
                loadComponent: () =>
                  import(
                    "./pages/page-report-monthly-revenues/page-report-monthly-revenues.component"
                  ).then((c) => c.PageReportMonthlyRevenuesComponent),
              },
            ],
          },
          {
            path: "accounts",
            data: { breadcrumb: "user.breadcrumb" },
            children: [
              {
                path: "headquarter-accounts",
                children: [
                  {
                    path: "",
                    loadComponent: () =>
                      import(
                        "./pages/page-accounts-headquarter-accounts-view/page-accounts-headquarter-accounts-view.component"
                      ).then(
                        (c) => c.PageAccountsHeadquarterAccountsViewComponent
                      ),
                  },
                  {
                    path: "create",
                    loadComponent: () =>
                      import(
                        "./pages/page-accounts-headquarter-accounts-create/page-accounts-headquarter-accounts-create.component"
                      ).then(
                        (c) => c.PageAccountsHeadquarterAccountsCreateComponent
                      ),
                  },
                  {
                    path: "update/:id",
                    loadComponent: () =>
                      import(
                        "./pages/page-accounts-headquarter-accounts-update/page-accounts-headquarter-accounts-update.component"
                      ).then(
                        (c) => c.PageAccountsHeadquarterAccountsUpdateComponent
                      ),
                  },
                ],
              },
              {
                path: "end-users",
                children: [
                  {
                    path: "",
                    loadComponent: () =>
                      import(
                        "./pages/page-accounts-end-users-view/page-accounts-end-users-view.component"
                      ).then((c) => c.PageAccountsEndUsersViewComponent),
                  },
                  {
                    path: "detail/:id",
                    loadComponent: () =>
                      import(
                        "./pages/page-accounts-end-users-detail/page-accounts-end-users-detail.component"
                      ).then((c) => c.PageAccountsEndUsersDetailComponent),
                  },
                ],
              },
              {
                path: "vip-qr-s",
                children: [
                  {
                    path: "",
                    loadComponent: () =>
                      import(
                        "./pages/page-accounts-vip-qr-s-view/page-accounts-vip-qr-s-view.component"
                      ).then((c) => c.PageAccountsVipQrSViewComponent),
                  },
                  {
                    path: "create",
                    loadComponent: () =>
                      import(
                        "./pages/page-accounts-vip-qr-s-create/page-accounts-vip-qr-s-create.component"
                      ).then((c) => c.PageAccountsVipQrSCreateComponent),
                  },
                  {
                    path: "detail/:id",
                    loadComponent: () =>
                      import(
                        "./pages/page-accounts-vip-qr-s-detail/page-accounts-vip-qr-s-detail.component"
                      ).then((c) => c.PageAccountsVipQrSDetailComponent),
                  },
                ],
              },
              {
                path: "software-accounts",
                children: [
                  {
                    path: "",
                    loadComponent: () =>
                      import(
                        "./pages/page-accounts-software-accounts-view/page-accounts-software-accounts-view.component"
                      ).then(
                        (c) => c.PageAccountsSoftwareAccountsViewComponent
                      ),
                  },
                  {
                    path: "create",
                    loadComponent: () =>
                      import(
                        "./pages/page-accounts-software-accounts-create/page-accounts-software-accounts-create.component"
                      ).then(
                        (c) => c.PageAccountsSoftwareAccountsCreateComponent
                      ),
                  },
                ],
              },
            ],
          },
          {
            path: "parking-stations",
            children: [
              {
                path: "",
                loadComponent: () =>
                  import(
                    "./pages/page-parking-stations-view/page-parking-stations-view.component"
                  ).then((c) => c.PageParkingStationsViewComponent),
              },
              {
                path: "create",
                loadComponent: () =>
                  import(
                    "./pages/page-parking-stations-create/page-parking-stations-create.component"
                  ).then((c) => c.PageParkingStationsCreateComponent),
              },
              {
                path: "detail/:parkingStationId",
                loadComponent: () =>
                  import(
                    "./pages/page-parking-stations-detail/page-parking-stations-detail.component"
                  ).then((c) => c.PageParkingStationsDetailComponent),
              },
              {
                path: "update/:parkingStationId",
                loadComponent: () =>
                  import(
                    "./pages/page-parking-stations-update/page-parking-stations-update.component"
                  ).then((c) => c.PageParkingStationsUpdateComponent),
              },
              {
                path: "parking-gates",
                children: [
                  {
                    path: "",
                    loadComponent: () =>
                      import(
                        "./pages/page-parking-stations-parking-gates-view/page-parking-stations-parking-gates-view.component"
                      ).then(
                        (c) => c.PageParkingStationsParkingGatesViewComponent
                      ),
                  },
                  {
                    path: "create",
                    loadComponent: () =>
                      import(
                        "./pages/page-parking-stations-parking-gates-create/page-parking-stations-parking-gates-create.component"
                      ).then(
                        (c) => c.PageParkingStationsParkingGatesCreateComponent
                      ),
                  },
                  {
                    path: "update/:parkingGateId",
                    loadComponent: () =>
                      import(
                        "./pages/page-parking-stations-parking-gates-update/page-parking-stations-parking-gates-update.component"
                      ).then(
                        (c) => c.PageParkingStationsParkingGatesUpdateComponent
                      ),
                  },
                  {
                    path: "tsp-devices/:parkingGateId",
                    loadComponent: () =>
                      import(
                        "./pages/page-parking-stations-parking-gates-tsp-devices/page-parking-stations-parking-gates-tsp-devices.component"
                      ).then(
                        (c) =>
                          c.PageParkingStationsParkingGatesTspDevicesComponent
                      ),
                  },
                ],
              },
              {
                path: "tsp-devices",
                children: [
                  {
                    path: "",
                    loadComponent: () =>
                      import(
                        "./pages/page-parking-stations-tsp-devices-view/page-parking-stations-tsp-devices-view.component"
                      ).then(
                        (c) => c.PageParkingStationsTspDevicesViewComponent
                      ),
                  },
                  {
                    path: "create",
                    loadComponent: () =>
                      import(
                        "./pages/page-parking-stations-tsp-devices-create/page-parking-stations-tsp-devices-create.component"
                      ).then(
                        (c) => c.PageParkingStationsTspDevicesCreateComponent
                      ),
                  },
                  {
                    path: "update/:parkingGateId",
                    loadComponent: () =>
                      import(
                        "./pages/page-parking-stations-tsp-devices-update/page-parking-stations-tsp-devices-update.component"
                      ).then(
                        (c) => c.PageParkingStationsTspDevicesUpdateComponent
                      ),
                  },
                ],
              },
            ],
          },
          {
            path: "parking-cards",
            data: { breadcrumb: "parking_card.breadcrumb" },
            children: [
              {
                path: "",
                loadComponent: () =>
                  import(
                    "./pages/page-parking-cards-view/page-parking-cards-view.component"
                  ).then((c) => c.PageParkingCardsViewComponent),
              },
              {
                path: "create",
                loadComponent: () =>
                  import(
                    "./pages/page-parking-cards-create/page-parking-cards-create.component"
                  ).then((c) => c.PageParkingCardsCreateComponent),
              },
              {
                path: "detail",
                loadComponent: () =>
                  import(
                    "./pages/page-parking-cards-detail/page-parking-cards-detail.component"
                  ).then((c) => c.PageParkingCardsDetailComponent),
              },
              {
                path: "update/:parkingCardId",
                loadComponent: () =>
                  import(
                    "./pages/page-parking-cards-update/page-parking-cards-update.component"
                  ).then((c) => c.PageParkingCardsUpdateComponent),
              },
            ],
          },
          {
            path: "services",
            data: { breadcrumb: "service.breadcrumb" },
            children: [
              {
                path: "",
                redirectTo: "parking-fee",
                pathMatch: "full",
              },
              {
                path: "parking-fee",
                loadComponent: () =>
                  import(
                    "./pages/page-services-parking-fee-view/page-services-parking-fee-view.component"
                  ).then((c) => c.PageServicesParkingFeeViewComponent),
              },
            ],
          },
          {
            path: "ads",
            data: { breadcrumb: "advertisement.breadcrumb" },
            children: [
              { path: "", redirectTo: "simple-ads", pathMatch: "full" },
              {
                path: "simple-ads",
                children: [
                  {
                    path: "",
                    loadComponent: () =>
                      import(
                        "./pages/page-ads-simple-ads-view/page-ads-simple-ads-view.component"
                      ).then((c) => c.PageAdsSimpleAdsViewComponent),
                  },
                  {
                    path: "create",
                    loadComponent: () =>
                      import(
                        "./pages/page-ads-simple-ads-create/page-ads-simple-ads-create.component"
                      ).then((c) => c.PageAdsSimpleAdsCreateComponent),
                  },
                  {
                    path: "update/:simpleAdId",
                    loadComponent: () =>
                      import(
                        "./pages/page-ads-simple-ads-update/page-ads-simple-ads-update.component"
                      ).then((c) => c.PageAdsSimpleAdsUpdateComponent),
                  },
                ],
              },
            ],
          },
          {
            path: "notifications",
            data: { breadcrumb: "advertisement.breadcrumb" },
            children: [
              {
                path: "",
                children: [
                  {
                    path: "",
                    loadComponent: () =>
                      import(
                        "./pages/page-notifications-notifications-view/page-notifications-notifications-view.component"
                      ).then(
                        (c) => c.PageNotificationsNotificationsViewComponent
                      ),
                  },
                  {
                    path: "create",
                    loadComponent: () =>
                      import(
                        "./pages/page-notifications-notifications-create/page-notifications-notifications-create.component"
                      ).then(
                        (c) => c.PageNotificationsNotificationsCreateComponent
                      ),
                  },
                  {
                    path: "update/:notificationId",
                    loadComponent: () =>
                      import(
                        "./pages/page-notifications-notifications-update/page-notifications-notifications-update.component"
                      ).then(
                        (c) => c.PageNotificationsNotificationsUpdateComponent
                      ),
                  },
                ],
              },
            ],
          },
          {
            path: "licenses",
            data: { breadcrumb: "advertisement.breadcrumb" },
            children: [
              { path: "", redirectTo: "tsp-licenses", pathMatch: "full" },
              {
                path: "tsp-licenses",
                children: [
                  {
                    path: "",
                    loadComponent: () =>
                      import(
                        "./pages/page-licenses-tsp-licenses-view/page-licenses-tsp-licenses-view.component"
                      ).then((c) => c.PageLicensesTspLicensesViewComponent),
                  },
                  {
                    path: "create",
                    loadComponent: () =>
                      import(
                        "./pages/page-licenses-tsp-licenses-create/page-licenses-tsp-licenses-create.component"
                      ).then((c) => c.PageLicensesTspLicensesCreateComponent),
                  },
                  {
                    path: "detail/:tspLicenseId",
                    loadComponent: () =>
                      import(
                        "./pages/page-licenses-tsp-licenses-detail/page-licenses-tsp-licenses-detail.component"
                      ).then((c) => c.PageLicensesTspLicensesDetailComponent),
                  },
                ],
              },
            ],
          },
          {
            path: "privileges",
            data: { breadcrumb: "advertisement.breadcrumb" },
            children: [
              { path: "", redirectTo: "allow-page-codes", pathMatch: "full" },
              {
                path: "allow-page-codes",
                children: [
                  {
                    path: "",
                    loadComponent: () =>
                      import(
                        "./pages/page-privileges-allow-page-codes-view/page-privileges-allow-page-codes-view.component"
                      ).then(
                        (c) => c.PagePrivilegesAllowPageCodesViewComponent
                      ),
                  },
                  {
                    path: "detail/:accountId",
                    loadComponent: () =>
                      import(
                        "./pages/page-privileges-allow-page-codes-detail/page-privileges-allow-page-codes-detail.component"
                      ).then(
                        (c) => c.PagePrivilegesAllowPageCodesDetailComponent
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "current-user",
    component: CurrentUserLayoutComponent,
    children: [
      {
        path: "me",
        data: { breadcrumb: "me.breadcrumb" },
        loadChildren: () =>
          import("./domains/me/me.module").then((m) => m.MeModule),
      },
      {
        path: "headquarters",
        data: { breadcrumb: "me.breadcrumb" },
        loadComponent: () =>
          import("./pages/headquarter-page/headquarter-page.component").then(
            (c) => c.HeadquarterPageComponent
          ),
      },
    ],
  },
  {
    path: "qr-code/confirm",
    loadChildren: () =>
      import("./domains/qr-code-confirm/qr-code-confirm.module").then(
        (m) => m.QrCodeConfirmModule
      ),
  },
  {
    path: "notfound",
    component: NotfoundComponent,
  },
  // { path: "**", redirectTo: "/notfound" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [provideRouter(routes, withComponentInputBinding())],
})
export class AppRoutingModule {}
