import { Component, OnInit } from "@angular/core";
import { ToastService } from "@services/toast.service";
import { MessageService } from "primeng/api";
import { Toast } from "src/app/models/front-end";

@Component({
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
})
export class ToastComponent implements OnInit {
  constructor(
    private _toastService: ToastService,
    private _messageService: MessageService
  ) {}

  ngOnInit(): void {
    this._toastService.pushNotification.subscribe({
      next: (res: Toast) => {
        this._messageService.add({
          severity: res.severity,
          summary: res.summary,
          detail: res.detail,
        });
      },
    });
  }
}
