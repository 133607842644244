<button
  class="layout-config-button p-link"
  type="button"
  (click)="onConfigButtonClick()"
>
  <i class="pi pi-cog"></i>
</button>

<p-sidebar
  [(visible)]="visible"
  position="right"
  [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
  styleClass="layout-config-sidebar w-18rem"
>
  <h5>Themes</h5>
  <div class="flex flex-wrap row-gap-3">
    <div class="w-3" *ngFor="let theme of componentThemes">
      <button
        type="button"
        class="cursor-pointer p-link w-2rem h-2rem border-circle flex-shrink-0"
        (click)="changeTheme(theme.name)"
        [ngStyle]="{ 'background-color': theme.color }"
      ></button>
    </div>
  </div>

  <h5>Scale</h5>
  <div class="flex align-items-center">
    <button
      icon="pi pi-minus"
      type="button"
      pButton
      (click)="decrementScale()"
      class="p-button-text p-button-rounded w-2rem h-2rem mr-2"
      [disabled]="scale === scales[0]"
    ></button>
    <div class="flex gap-2 align-items-center">
      <i
        class="pi pi-circle-fill text-300"
        *ngFor="let s of scales"
        [ngClass]="{ 'text-primary-500': s === scale }"
      ></i>
    </div>
    <button
      icon="pi pi-plus"
      type="button"
      pButton
      (click)="incrementScale()"
      class="p-button-text p-button-rounded w-2rem h-2rem ml-2"
      [disabled]="scale === scales[scales.length - 1]"
    ></button>
  </div>

  <ng-container *ngIf="!minimal">
    <h5>Menu Type</h5>
    <div class="field-radiobutton">
      <p-radioButton
        name="menuMode"
        value="static"
        [(ngModel)]="menuMode"
        inputId="mode1"
      ></p-radioButton>
      <label for="mode1">Static</label>
    </div>
    <!-- <div class="field-radiobutton">
      <p-radioButton
        name="menuMode"
        value="overlay"
        [(ngModel)]="menuMode"
        inputId="mode2"
      ></p-radioButton>
      <label for="mode2">Overlay</label>
    </div> -->
    <div class="field-radiobutton">
      <p-radioButton
        name="menuMode"
        value="slim"
        [(ngModel)]="menuMode"
        inputId="mode3"
      ></p-radioButton>
      <label for="mode3">Slim</label>
    </div>
    <!-- <div class="field-radiobutton">
      <p-radioButton
        name="menuMode"
        value="horizontal"
        [(ngModel)]="menuMode"
        inputId="mode4"
      ></p-radioButton>
      <label for="mode4">Horizontal</label>
    </div> -->

    <h5>Menu Theme</h5>
    <div class="field-radiobutton">
      <p-radioButton
        name="menuTheme"
        value="colorScheme"
        [(ngModel)]="menuTheme"
        inputId="menutheme-colorscheme"
      ></p-radioButton>
      <label for="menutheme-colorscheme">Color Scheme</label>
    </div>
    <div class="field-radiobutton">
      <p-radioButton
        name="menuTheme"
        value="primaryColor"
        [(ngModel)]="menuTheme"
        inputId="menutheme-primarycolor"
      ></p-radioButton>
      <label for="menutheme-primarycolor">Primary Color</label>
    </div>
    <div class="field-radiobutton">
      <p-radioButton
        name="menuTheme"
        value="transparent"
        [(ngModel)]="menuTheme"
        inputId="menutheme-transparent"
      ></p-radioButton>
      <label for="menutheme-transparent">Transparent</label>
    </div>
  </ng-container>

  <h5>Color Scheme</h5>
  <div class="field-radiobutton">
    <p-radioButton
      name="colorScheme"
      value="light"
      [(ngModel)]="colorScheme"
      inputId="mode-light"
    ></p-radioButton>
    <label for="mode-light">Light</label>
  </div>
  <div class="field-radiobutton">
    <p-radioButton
      name="colorScheme"
      value="dim"
      [(ngModel)]="colorScheme"
      inputId="mode-dim"
    ></p-radioButton>
    <label for="mode-dim">Dim</label>
  </div>
  <div class="field-radiobutton">
    <p-radioButton
      name="colorScheme"
      value="dark"
      [(ngModel)]="colorScheme"
      inputId="mode-dark"
    ></p-radioButton>
    <label for="mode-dark">Dark</label>
  </div>

  <ng-container *ngIf="!minimal">
    <h5>Input Style</h5>
    <div class="flex">
      <div class="field-radiobutton flex-1">
        <p-radioButton
          name="inputStyle"
          value="outlined"
          [(ngModel)]="inputStyle"
          inputId="outlined_input"
        ></p-radioButton>
        <label for="outlined_input">Outlined</label>
      </div>
      <div class="field-radiobutton flex-1">
        <p-radioButton
          name="inputStyle"
          value="filled"
          [(ngModel)]="inputStyle"
          inputId="filled_input"
        ></p-radioButton>
        <label for="filled_input">Filled</label>
      </div>
    </div>

    <h5>Ripple Effect</h5>
    <p-inputSwitch [(ngModel)]="ripple"></p-inputSwitch>
  </ng-container>
</p-sidebar>
