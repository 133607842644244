/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Filter } from "@models/front-end";
import { PrivilegeClass } from "@models/privilege";

export const searchElementById = (
  id: string,
  listElements: { id: string; [key: string]: any }[]
) => {
  return listElements.find((element) => element.id == id);
};

export const searchElementByCode = (
  code: string,
  listElements: { code: string | number | boolean; [key: string]: any }[]
) => {
  return listElements.find((element) => element.code == code);
};

export const searchIndexById = (
  id: string,
  list: { id: string; [key: string]: unknown }[]
) => {
  return list.findIndex((item) => item.id == id);
};

export const isError = (
  field: string,
  validatorError: string,
  form: FormGroup
) => {
  return (
    form.status &&
    (form?.controls?.[field]?.dirty || form?.controls?.[field]?.touched) &&
    form?.controls?.[field]?.errors?.[validatorError]
  );
};

export const deletePropNull = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] == null || obj[key] == undefined) {
      delete obj[key];
    }
  });
};

export const convertPrivilegeCodeToPrivilegeObject = (privilegeCode: {
  id?: string;
  nameSpace?: string;
  resourceId?: string;
  resourceType?: string;
  privilegeCode: string;
  translationCode: string;
}) => {
  return new PrivilegeClass(privilegeCode);
};

export const renderPercent = (numerator?: number, denomerator?: number) => {
  if (!numerator || !denomerator) return 0;
  else if ((numerator / denomerator) * 100 < 0) return 0;
  else if (
    (numerator / denomerator) * 100 < 1 &&
    (numerator / denomerator) * 100 > 0
  )
    return 1;
  else if (
    (numerator / denomerator) * 100 > 99 &&
    (numerator / denomerator) * 100 < 100
  )
    return 99;
  else if (
    (numerator / denomerator) * 100 > 100 &&
    (numerator / denomerator) * 100 < 101
  )
    return 101;
  else {
    return Math.floor((numerator / denomerator) * 100);
  }
};

export const convertArrayFilterToStringFilter = (arr: Filter[]) => {
  return arr
    .filter(
      (filter: Filter) =>
        filter.value || filter.value === 0 || filter.value === false
    )
    .map((filter: Filter) => `${filter.field}${filter.operator}${filter.value}`)
    .join(",");
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapValueToObjectContructor = (refObject: any, value: any) => {
  if (value)
    Object.keys(refObject).forEach((key: string) => {
      if (Object.prototype.hasOwnProperty.call(value, key))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (refObject as any)[key] = (value as any)[key];
    });
};

export const convertBlobToObjectURL = (blob: any): SafeResourceUrl => {
  const domSanitizer = inject(DomSanitizer);
  const objectURL = URL.createObjectURL(blob.body);
  return domSanitizer.bypassSecurityTrustResourceUrl(objectURL);
};
