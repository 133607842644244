import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { HeadquarterAccountCreate } from "@features/accounts/interfaces/headquarter-account-create.interface";
import { HeadquarterAccountUpdate } from "@features/accounts/interfaces/headquarter-account-update.interface";
import { HeadquarterAccount } from "@features/accounts/interfaces/headquarter-account.interface";
import { QueryParams } from "@interfaces/query-params.interface";
import { HeadquarterV2 } from "@models/headquarter";
import { BaseResponse } from "@models/response";
import { RoleAssociation } from "@models/role-association";
import { EndUserAccountV2 } from "@models/user-account";
import { autoFillFieldParams } from "@utils/auto-fill-field-params";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class HeadquarterAccountControllerService {
  private _http = inject(HttpClient);

  getCurrent() {
    return this._http.get<BaseResponse<EndUserAccountV2>>(
      "/api/headquarter-accounts/current"
    );
  }

  getCurrentAvatarFile() {
    return this._http.get(`/api/headquarter-accounts/current/avatar/file`, {
      observe: "response",
      responseType: "blob",
    });
  }

  updateCurrentAvatarFile(file: string) {
    const formData = new FormData();
    formData.append("file", file);
    return this._http.put(
      `/api/headquarter-accounts/current/avatar/file`,
      formData
    );
  }

  getRoleAssociation(
    headquarterId: string
  ): Observable<BaseResponse<RoleAssociation>> {
    return this._http.get<BaseResponse<RoleAssociation>>(
      `/api/headquarters/${headquarterId}/accounts/current/role-association`
    );
  }

  getCurrentHeadquarters(): Observable<BaseResponse<HeadquarterV2[]>> {
    return this._http.get<BaseResponse<HeadquarterV2[]>>(
      `/api/headquarter-accounts/current/headquarters`
    );
  }

  getHeadquarterAccountsByHeadquarterId(
    headquarterId: string,
    query?: QueryParams
  ): Observable<BaseResponse<HeadquarterAccount[]>> {
    const filledQuery = autoFillFieldParams(query);
    return this._http.get<BaseResponse<HeadquarterAccount[]>>(
      `/api/headquarters/${headquarterId}/accounts`,
      { params: filledQuery }
    );
  }

  getHeadquarterAccountById(
    headquarterId: string,
    accountId: string
  ): Observable<BaseResponse<HeadquarterAccount>> {
    return this._http.get<BaseResponse<HeadquarterAccount>>(
      `/api/headquarters/${headquarterId}/accounts/${accountId}`
    );
  }

  getRoleAssociationOfAccount(
    headquarterId: string,
    accountId: string
  ): Observable<BaseResponse<RoleAssociation>> {
    return this._http.get<BaseResponse<RoleAssociation>>(
      `/api/headquarters/${headquarterId}/accounts/${accountId}/role-association`
    );
  }

  updateAllowPageCodes(
    headquarterId: string,
    accountId: string,
    body: { allowPageCodes: string }
  ): Observable<BaseResponse<RoleAssociation>> {
    return this._http.put<BaseResponse<RoleAssociation>>(
      `/api/headquarters/${headquarterId}/accounts/${accountId}/role-association`,
      body
    );
  }

  createHeadquarterAccount(
    headquarterId: string,
    body: { data: HeadquarterAccountCreate[] }
  ): Observable<BaseResponse<HeadquarterAccount>> {
    return this._http.post<BaseResponse<HeadquarterAccount>>(
      `/api/headquarters/${headquarterId}/accounts`,
      body
    );
  }

  updateHeadquarterAccount(
    accountId: string,
    headquarterId: string,
    body: HeadquarterAccountUpdate
  ): Observable<BaseResponse<HeadquarterAccount>> {
    return this._http.put<BaseResponse<HeadquarterAccount>>(
      `/api/headquarters/${headquarterId}/accounts/${accountId}`,
      body
    );
  }

  deleteHeadquarterAccount(
    accountId: string,
    headquarterId: string
  ): Observable<BaseResponse<HeadquarterAccount>> {
    return this._http.delete<BaseResponse<HeadquarterAccount>>(
      `/api/headquarters/${headquarterId}/accounts/${accountId}`
    );
  }
}
