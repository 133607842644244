import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Toast } from "src/app/models/front-end";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  public pushNotification = new Subject<Toast>();
}
