import { Injectable } from "@angular/core";
import { LocalStorageService } from "@services/local-storage.service";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  public componentThemes = [
    { name: "indigo", color: "#6366F1" },
    { name: "blue", color: "#3B82F6" },
    { name: "purple", color: "#8B5CF6" },
    { name: "teal", color: "#14B8A6" },
    { name: "cyan", color: "#06b6d4" },
    { name: "green", color: "#10b981" },
    { name: "orange", color: "#f59e0b" },
    { name: "pink", color: "#d946ef" },
  ];

  public isRefreshTheme = new Subject<boolean>();
  public curTheme = new BehaviorSubject<string>("");

  constructor(private localStorageService: LocalStorageService) {
    this.isRefreshTheme.subscribe(() => {
      this.setTheme();
    });

    this.isRefreshTheme.next(true);
  }

  setTheme() {
    const themeLocalStorage = this.localStorageService.getData("theme");

    if (themeLocalStorage) {
      const theme = this.componentThemes.find(
        (item: { name: string; color: string }) =>
          item.name === themeLocalStorage
      );

      if (theme) {
        this.curTheme.next(theme.color);
      } else {
        this.curTheme.next("#10b981");
      }
    }
  }
}
