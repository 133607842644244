import { PRIVILEGE_TYPE } from "@constants/privilege-type";
import { Nav } from "@interfaces/nav.interface";

export const NAVS: Nav[] = [
  {
    label: "dashboard.menu",
    icon: "pi pi-th-large",
    items: [
      {
        label: "dashboard.general.menu",
        icon: "pi pi-fw pi-home",
        routerLink: ["/headquarters", ":headquarterId", "dashboard", "general"],
        privilegeTypes: [PRIVILEGE_TYPE.view],
      },
      {
        label: "Thiết bị",
        icon: "pi pi-fw pi-home",
        routerLink: ["/headquarters", ":headquarterId", "dashboard", "device"],
        privilegeTypes: [PRIVILEGE_TYPE.view],
      },
    ],
  },
  {
    label: "report.menu",
    icon: "pi pi-th-large",
    items: [
      {
        label: "Lịch sử vào ra",
        icon: "pi pi-fw pi-history",
        routerLink: [
          "/headquarters",
          ":headquarterId",
          "report",
          "parking-sessions",
        ],
        privilegeTypes: [PRIVILEGE_TYPE.view, PRIVILEGE_TYPE.detail],
      },
      {
        label: "Báo cáo xe còn trong bãi",
        icon: "pi pi-fw pi-chart-bar",
        routerLink: ["/headquarters", ":headquarterId", "report", "vehicle-in"],
        privilegeTypes: [PRIVILEGE_TYPE.view, PRIVILEGE_TYPE.detail],
      },
      {
        label: "Báo cáo thẻ tháng đã đăng kí",
        icon: "pi pi-fw pi-chart-bar",
        routerLink: [
          "/headquarters",
          ":headquarterId",
          "report",
          "registered-monthly-parking-cards",
        ],
        privilegeTypes: [PRIVILEGE_TYPE.view, PRIVILEGE_TYPE.detail],
      },
      {
        label: "report.daily_revenues.menu",
        icon: "pi pi-fw pi-chart-line",
        routerLink: [
          "/headquarters",
          ":headquarterId",
          "report",
          "daily-revenues",
        ],
        privilegeTypes: [PRIVILEGE_TYPE.view],
      },
      {
        label: "report.monthly_revenues.menu",
        icon: "pi pi-fw pi-chart-line",
        routerLink: [
          "/headquarters",
          ":headquarterId",
          "report",
          "monthly-revenues",
        ],
        privilegeTypes: [PRIVILEGE_TYPE.view],
      },
    ],
  },
  {
    label: "user.menu",
    icon: "pi pi-fw pi-briefcase",
    items: [
      {
        label: "user.account.menu",
        icon: "pi pi-fw pi-user",
        routerLink: [
          "/headquarters",
          ":headquarterId",
          "accounts",
          "headquarter-accounts",
        ],
        privilegeTypes: [
          PRIVILEGE_TYPE.view,
          PRIVILEGE_TYPE.create,
          PRIVILEGE_TYPE.update,
          PRIVILEGE_TYPE.delete,
        ],
      },
      {
        label: "user.end_user.menu",
        icon: "pi pi-fw pi-user",
        routerLink: [
          "/headquarters",
          ":headquarterId",
          "accounts",
          "end-users",
        ],
        privilegeTypes: [
          PRIVILEGE_TYPE.view,
          PRIVILEGE_TYPE.active,
          PRIVILEGE_TYPE.detail,
        ],
      },
      {
        label: "Vip",
        icon: "pi pi-fw pi-users",
        routerLink: ["/headquarters", ":headquarterId", "accounts", "vip-qr-s"],
        privilegeTypes: [
          PRIVILEGE_TYPE.view,
          PRIVILEGE_TYPE.create,
          PRIVILEGE_TYPE.delete,
          PRIVILEGE_TYPE.detail,
        ],
      },
      {
        label: "Tài khoản phần mềm",
        icon: "pi pi-fw pi-users",
        routerLink: [
          "/headquarters",
          ":headquarterId",
          "accounts",
          "software-accounts",
        ],
        privilegeTypes: [
          PRIVILEGE_TYPE.view,
          PRIVILEGE_TYPE.create,
          PRIVILEGE_TYPE.delete,
        ],
      },
    ],
  },
  {
    label: "parking_station.menu",
    icon: "pi pi-fw pi-briefcase",
    items: [
      {
        label: "parking_station.parking_station_manager.menu",
        icon: "pi pi-fw pi-car",
        routerLink: ["/headquarters", ":headquarterId", "parking-stations"],
        privilegeTypes: [
          PRIVILEGE_TYPE.view,
          PRIVILEGE_TYPE.create,
          PRIVILEGE_TYPE.update,
          PRIVILEGE_TYPE.delete,
          PRIVILEGE_TYPE.detail,
        ],
      },
      {
        label: "Cổng",
        icon: "pi pi-fw pi-desktop",
        routerLink: [
          "/headquarters",
          ":headquarterId",
          "parking-stations",
          "parking-gates",
        ],
        privilegeTypes: [
          PRIVILEGE_TYPE.view,
          PRIVILEGE_TYPE.create,
          PRIVILEGE_TYPE.update,
          PRIVILEGE_TYPE.delete,
          PRIVILEGE_TYPE.detail,
        ],
      },
      {
        label: "Thiết bị",
        icon: "pi pi-fw pi-desktop",
        routerLink: [
          "/headquarters",
          ":headquarterId",
          "parking-stations",
          "tsp-devices",
        ],
        privilegeTypes: [
          PRIVILEGE_TYPE.view,
          PRIVILEGE_TYPE.create,
          PRIVILEGE_TYPE.update,
          PRIVILEGE_TYPE.delete,
        ],
      },
    ],
  },
  {
    label: "parking_card.menu",
    icon: "pi pi-fw pi-briefcase",
    items: [
      {
        label: "parking_card.parking_card_manager.menu",
        icon: "pi pi-fw pi-credit-card",
        routerLink: ["/headquarters", ":headquarterId", "parking-cards"],
        privilegeTypes: [
          PRIVILEGE_TYPE.view,
          PRIVILEGE_TYPE.create,
          PRIVILEGE_TYPE.update,
          PRIVILEGE_TYPE.delete,
          PRIVILEGE_TYPE.active,
          PRIVILEGE_TYPE.detail,
        ],
      },
    ],
  },
  {
    label: "service.menu",
    icon: "pi pi-fw pi-briefcase",
    items: [
      {
        label: "service.parking_fee.menu",
        icon: "pi pi-fw pi-dollar",
        routerLink: [
          "/headquarters",
          ":headquarterId",
          "services",
          "parking-fee",
        ],
        privilegeTypes: [PRIVILEGE_TYPE.view, PRIVILEGE_TYPE.update],
      },
    ],
  },
  {
    label: "advertisement.menu",
    icon: "pi pi-fw pi-briefcase",
    items: [
      {
        label: "advertisement.simple_ad_manager.menu",
        icon: "pi pi-fw pi-image",
        routerLink: ["/headquarters", ":headquarterId", "ads", "simple-ads"],
        privilegeTypes: [
          PRIVILEGE_TYPE.view,
          PRIVILEGE_TYPE.create,
          PRIVILEGE_TYPE.update,
          PRIVILEGE_TYPE.delete,
        ],
      },
    ],
  },
  {
    label: "notification.menu",
    icon: "pi pi-fw pi-briefcase",
    items: [
      {
        label: "notification.notification.menu",
        icon: "pi pi-fw pi-bell",
        routerLink: ["/headquarters", ":headquarterId", "notifications"],
        privilegeTypes: [
          PRIVILEGE_TYPE.view,
          PRIVILEGE_TYPE.create,
          PRIVILEGE_TYPE.update,
          PRIVILEGE_TYPE.delete,
          PRIVILEGE_TYPE.pushSMS,
          PRIVILEGE_TYPE.pushImmediate,
        ],
      },
    ],
  },
  {
    label: "License",
    icon: "pi pi-fw pi-briefcase",
    items: [
      {
        label: "Bãi đậu xe",
        icon: "pi pi-fw pi-bell",
        privilegeTypes: [
          PRIVILEGE_TYPE.view,
          PRIVILEGE_TYPE.create,
          PRIVILEGE_TYPE.update,
          PRIVILEGE_TYPE.delete,
          PRIVILEGE_TYPE.active,
        ],
        routerLink: [
          "/headquarters",
          ":headquarterId",
          "licenses",
          "tsp-licenses",
        ],
      },
    ],
  },
  {
    label: "Phân quyền",
    icon: "pi pi-fw pi-briefcase",
    items: [
      {
        label: "Theo chức năng",
        icon: "pi pi-fw pi-bell",
        routerLink: [
          "/headquarters",
          ":headquarterId",
          "privileges",
          "allow-page-codes",
        ],
        privilegeTypes: [PRIVILEGE_TYPE.view, PRIVILEGE_TYPE.update],
      },
    ],
  },
];
