/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"],
})
export class UploadFileComponent {
  @Output() getFile = new EventEmitter<File>();
  @Input() url: string | SafeResourceUrl = "";
  @Input() width = "50";
  @Input() height = "50";
  @Input() isShowFileName = true;

  public file?: File;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpload(event: Event): void {
    this.file = (event.target as HTMLInputElement)?.files?.[0];

    if (this.file) {
      const reader = new FileReader();

      reader.readAsDataURL(this.file);
      reader.onload = () => {
        if (reader.result && typeof reader.result === "string") {
          this.url = reader.result;
        }
      };

      this.getFile.next(this.file);
    }
  }
}
