<div class="layout-container" [ngClass]="containerClass">
  <div class="layout-content p-4">
    <div class="layout-topbar">
      <div class="topbar-start">
        <a [routerLink]="['/']" class="app-logo h-3rem">
          <div
            class="text-900 text-2xl md:text-3xl font-bold text-primary md:pl-4 md:pr-4 app-logo-normal h-full hidden md:block"
            pRipple
            type="button"
            style="border-radius: 0.5rem; margin-left: auto; margin-right: auto"
          >
            <img
              src="/assets/layout/images/logo-large.png"
              alt=""
              class="h-full"
            />
          </div>

          <div
            class="text-900 text-2xl md:text-3xl font-bold text-primary pt-2 pb-2 pl-4 pr-4 app-logo-small h-full block md:hidden"
            pRipple
            style="border-radius: 0.5rem; margin-left: auto; margin-right: auto"
          >
            <img
              src="/assets/layout/images/logo-small.png"
              alt=""
              class="h-full"
            />
          </div>
        </a>
      </div>

      <div class="topbar-end flex">
        <ul class="topbar-menu">
          <li class="topbar-profile">
            <button
              type="button"
              class="p-link"
              (click)="onProfileButtonClick()"
              style="border-radius: 50%; overflow: hidden"
            >
              <img
                [src]="
                  this.meStore.profile_()?.avatar ||
                  'assets/layout/images/avatar-default.png'
                "
                alt="Profile"
              />
            </button>
          </li>
        </ul>
      </div>
    </div>

    <router-outlet></router-outlet>
    <app-profilemenu></app-profilemenu>
    <app-config></app-config>
    <div class="layout-mask"></div>
  </div>
</div>
