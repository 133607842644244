import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ConfigComponent } from "@layout/components/config/config.component";
import { ProfileSidebarComponent } from "@layout/components/profile-sidebar/profile-sidebar.component";
import { LayoutService } from "@layout/service/app.layout.service";
import { MeStoreService } from "@services/me-store.service";

@Component({
  selector: "app-current-user-layout",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ProfileSidebarComponent,
    ConfigComponent,
  ],
  templateUrl: "./current-user-layout.component.html",
  styleUrl: "./current-user-layout.component.scss",
})
export class CurrentUserLayoutComponent {
  public meStore = inject(MeStoreService);
  public layoutService = inject(LayoutService);

  onProfileButtonClick() {
    this.layoutService.showProfileSidebar();
  }

  get containerClass() {
    return {
      "layout-light": this.layoutService.config.colorScheme === "light",
      "layout-dim": this.layoutService.config.colorScheme === "dim",
      "layout-dark": this.layoutService.config.colorScheme === "dark",
      "layout-colorscheme-menu":
        this.layoutService.config.menuTheme === "colorScheme",
      "layout-primarycolor-menu":
        this.layoutService.config.menuTheme === "primaryColor",
      "layout-transparent-menu":
        this.layoutService.config.menuTheme === "transparent",
      "layout-overlay": this.layoutService.config.menuMode === "overlay",
      "layout-static": this.layoutService.config.menuMode === "static",
      "layout-slim": this.layoutService.config.menuMode === "slim",
      "layout-horizontal": this.layoutService.config.menuMode === "horizontal",
      "layout-static-inactive":
        this.layoutService.state.staticMenuDesktopInactive &&
        this.layoutService.config.menuMode === "static",
      "layout-overlay-active": this.layoutService.state.overlayMenuActive,
      "layout-mobile-active": this.layoutService.state.staticMenuMobileActive,
      "p-input-filled": this.layoutService.config.inputStyle === "filled",
      "p-ripple-disabled": !this.layoutService.config.ripple,
    };
  }
}
