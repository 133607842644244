<div class="layout-sidebar">
  <a [routerLink]="['/']" class="app-logo pt-4 md:pt-5 pb-4 md:pb-5">
    <div
      class="text-900 text-2xl md:text-3xl font-bold text-primary md:pl-4 md:pr-4 app-logo-normal"
      pRipple
      type="button"
      style="border-radius: 0.5rem; margin-left: auto; margin-right: auto"
    >
      <img
        src="../../assets/layout/images/logo-large.png"
        alt=""
        class="w-full"
      />
    </div>

    <div
      class="text-900 text-2xl md:text-3xl font-bold text-primary pt-2 pb-2 pl-4 pr-4 app-logo-small"
      pRipple
      style="border-radius: 0.5rem; margin-left: auto; margin-right: auto"
    >
      <img
        src="../../assets/layout/images/logo-small.png"
        alt=""
        class="w-full"
      />
    </div>
  </a>

  <div class="layout-menu-container">
    <app-menu></app-menu>
  </div>
</div>
