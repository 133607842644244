import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "@services/cookie.service";

@Component({
  templateUrl: "./notfound.component.html",
})
export class NotfoundComponent {
  constructor(
    private cookieService: CookieService,
    private router: Router
  ) {}

  redirectToHome() {
    if (this.cookieService.getCookie("token")) {
      this.router.navigateByUrl("/");
    } else this.router.navigateByUrl("/auth/login");
  }
}
