import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { TIME_TYPE } from "@constants/type";
import { Filter, ReportFilter } from "@models/front-end";
import { ParkingStation, ParkingStationV2 } from "@models/parking-station";
import { convertArrayFilterToStringFilter } from "@utils/function";
import moment from "moment";
import { CalendarTypeView } from "primeng/calendar";

@Component({
  selector: "app-report-filter",
  templateUrl: "./report-filter.component.html",
  styleUrls: ["./report-filter.component.scss"],
})
export class ReportFilterComponent implements OnChanges {
  public TIME_TYPE = TIME_TYPE;

  @Input() time: { start: Date; end: Date } = {
    start: new Date(),
    end: new Date(),
  };
  @Input() listParkingStations: ParkingStationV2[] = [];
  @Input() dateFormat = "dd-mm-yy";
  @Input() view: CalendarTypeView = "date";
  @Input() stepMinute = 5;
  @Output() getReportFilter = new EventEmitter<ReportFilter>();

  public selectedParkingStation: ParkingStation = {} as ParkingStation;

  ngOnChanges(): void {
    if (this.time && this.listParkingStations.length > 0) {
      this.selectedParkingStation = this.listParkingStations[0];
      this.emitValue();
    }
  }

  onChangeTimeFilter(event: Date, type: string) {
    const value = event;
    if (type == this.TIME_TYPE.START.code) {
      this.time.start = value;
    } else this.time.end = value;
    this.emitValue();
  }

  onChangeParkingStation(value: ParkingStation) {
    this.selectedParkingStation = value;
    this.emitValue();
  }

  emitValue() {
    let startTimeString = "";
    let endTimeString = "";

    if (this.view == "date") {
      startTimeString = moment(this.time.start).format("YYYYMMDD");
      endTimeString = moment(this.time.end).format("YYYYMMDD");
    } else if (this.view == "month") {
      startTimeString = moment(this.time.start).format("YYYYMM");

      endTimeString = moment(this.time.end).format("YYYYMM");
    }

    const arrayFilter: Filter[] = [
      {
        field: "reportTimeCode",
        operator: ">=",
        value: startTimeString,
      },
      {
        field: "reportTimeCode",
        operator: "<=",
        value: endTimeString,
      },
    ];

    this.getReportFilter.emit({
      time: convertArrayFilterToStringFilter(arrayFilter),
      parkingStationId: this.selectedParkingStation.id,
    });
  }
}
