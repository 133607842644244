import { inject } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { ActivatedRouteSnapshot, CanActivateFn, Router } from "@angular/router";
import { NOTIFICATION_TYPE } from "@constants/type";
import { MeStoreService } from "@services/me-store.service";
import { ToastService } from "@services/toast.service";
import { Observable, filter, map } from "rxjs";

class IsOwnerHeadquarterId {
  static canActivate(
    route: ActivatedRouteSnapshot,
    meStore: MeStoreService,
    toast: ToastService,
    router: Router
  ): Observable<boolean> {
    return toObservable(meStore.headquarters_).pipe(
      filter((r) => !!r && r.length > 0),
      map((headquarters) => {
        const curHeadquarterId = route.paramMap.get("headquarterId");

        const headquarterIds = headquarters?.map((r) => r.id);
        if (
          curHeadquarterId &&
          headquarterIds &&
          headquarterIds.includes(curHeadquarterId)
        ) {
          return true;
        }

        toast.pushNotification.next({
          severity: NOTIFICATION_TYPE.WARN,
          detail: "Headquarter không hợp lệ",
        });

        router.navigateByUrl("/headquarters");
        return false;
      })
    );
  }
}

export const IsOwnerHeadquarterIdGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
) => {
  return IsOwnerHeadquarterId.canActivate(
    route,
    inject(MeStoreService),
    inject(ToastService),
    inject(Router)
  );
};
