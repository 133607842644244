export interface PrivilegeCreate {
  nameSpace: string;
  privilegeCode: string;
  resourceId: string;
  resourceType: string;
}

export interface Privilege {
  id?: string;
  privilegeCode: string;
  translationCode: string;
  nameSpace?: string;
  resourceId?: string;
  resourceType?: string;
}

export class PrivilegeClass {
  id?: string;
  translationCode?: string;
  privilegeCode?: string;
  nameSpace?: string;
  resourceId?: string;
  resourceType?: string;

  constructor({
    id,
    translationCode,
    privilegeCode,
    nameSpace,
    resourceId,
    resourceType,
  }: Privilege) {
    this.id = id;
    this.translationCode = translationCode;
    this.privilegeCode = privilegeCode;
    this.nameSpace = nameSpace;
    this.resourceId = resourceId;
    this.resourceType = resourceType;
  }
}
