import { CommonModule } from "@angular/common";
import { Component, effect, inject, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { EndUserAccountV2 } from "@models/user-account";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { CookieService } from "@services/cookie.service";
import { LocalStorageService } from "@services/local-storage.service";
import { MeStoreService } from "@services/me-store.service";
import { ProcessTranslateService } from "@services/process-translate.service";
import { RouteParamService } from "@services/route-param.service";
import { SidebarService } from "@services/sidebar.service";
import { DropdownModule } from "primeng/dropdown";
import { SidebarModule } from "primeng/sidebar";
import { LayoutService } from "../../service/app.layout.service";

type Country = {
  name: string;
  code: string;
  i18n: string;
};
@Component({
  selector: "app-profilemenu",
  templateUrl: "./profile-sidebar.component.html",
  standalone: true,
  imports: [
    SidebarModule,
    TranslateModule,
    DropdownModule,
    FormsModule,
    CommonModule,
  ],
})
export class ProfileSidebarComponent implements OnInit {
  private _sidebarService = inject(SidebarService);
  private _meStore = inject(MeStoreService);
  private _routeParamService = inject(RouteParamService);

  public myProfile: EndUserAccountV2 | null = null;
  countries: Country[] = [
    { name: "Vietnam", code: "vn", i18n: "vi" },
    { name: "United States", code: "us", i18n: "en" },
  ];

  public selectedCountry?: Country;

  constructor(
    public layoutService: LayoutService,
    private cookie: CookieService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private processTranslateService: ProcessTranslateService,
    private router: Router
  ) {
    effect(() => {
      this.myProfile = this._meStore.profile_();
    });
  }

  ngOnInit() {
    this.initCountry();
    this.changeLanguage();
  }

  initCountry() {
    const language = this.localStorageService.getData("language");
    if (language) {
      this.selectedCountry = this.countries.find(
        (country) => country.i18n == language
      );
    } else {
      this.selectedCountry = this.countries.find(
        (country) => country.i18n == "vi"
      );
    }
  }

  get visible(): boolean {
    return this.layoutService.state.profileSidebarVisible;
  }

  set visible(_val: boolean) {
    this.layoutService.state.profileSidebarVisible = _val;
  }

  logout() {
    this.cookie.deleteCookie("jwt_token");
    this.router.navigateByUrl("/auth/login");
    this._meStore.profile_.set(null);
    this._routeParamService.headquarterId_.set(null);
    this.visible = false;
  }

  changeLanguage() {
    if (this.selectedCountry) {
      this.translateService.use(this.selectedCountry.i18n);
      this.localStorageService.saveData("language", this.selectedCountry.i18n);
      this._sidebarService.isRefresh$.next(true);
      this.processTranslateService.isChangedLanguage.next(true);
    }
  }

  onClickProfileTab() {
    this.layoutService.state.profileSidebarVisible = false;
    this.router.navigateByUrl("/current-user/me/profile");
  }

  onClickHeaquarterTab() {
    this.layoutService.state.profileSidebarVisible = false;
    this.router.navigateByUrl("/current-user/headquarters");
  }
}
