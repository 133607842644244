import { CommonModule } from "@angular/common";
import { Component, computed, inject, input } from "@angular/core";
import { EmptyObjectComponent } from "@components/empty-object/empty-object.component";
import { MeStoreService } from "@services/me-store.service";
import { ProgressSpinnerModule } from "primeng/progressspinner";
@Component({
  selector: "app-empty-headquarter-page",
  standalone: true,
  imports: [EmptyObjectComponent, CommonModule, ProgressSpinnerModule],
  templateUrl: "./empty-headquarter-page.component.html",
  styleUrl: "./empty-headquarter-page.component.scss",
})
export class EmptyHeadquarterPageComponent {
  private _meStore = inject(MeStoreService);
  title_ = input<string>("", { alias: "title" });
  message_ = input<string>("", { alias: "message" });
  backBtn_ = input<{ url: string; label: string; class?: string } | null>(
    null,
    {
      alias: "backBtn",
    }
  );

  headquarters_ = computed(() => this._meStore.headquarters_());
}
